import React from "react";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Main from "./Main";
import WhyUs from "./WhyUs";
import JoinMovement from "./JoinMovement";
import Footer from "./Footer";
import Hero from "./Hero";
import SixtyDays from "./SixtyDays";
import Reward from "./Reward";
import SignHealth from "./SignHealth";
import Blog from "./Blog";

export default function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <Main />
      <WhyUs />
      <JoinMovement />
      <SixtyDays/>
      <Reward />
      <SignHealth/>
      <Blog/>
      <Footer />
    </>
  );
}
