import React from "react";
import google from "../assets/img/goggle.png";
import ios from "../assets/img/appstore.png";
import star from "../assets/img/icons/star.svg";

export default function SixtyDays() {
  return (
    <section id="why-us" className="why-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
            <div className="content">
              <div className="d-flex">
                <div className="side-1-line sixty-days"></div>
                <div className="about-nobesity">
                  <p>INDIA FIGHTS WITH OBESITY</p>
                  <h3>
                    Nobesity:
                    <span className="org-text"> The 60 Days Challenge</span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="accordion-list">
              <div className="about-details">
                <p>
                  To kickstart this mission, we present the Nobesity 60 Days
                  Challenge. Register on the {" "}
                  <span className="bold-about" style={{ paddingRight: "0px" }}>
                    Nobesity app, calculate your BMI,
                  </span> {" "}
                  and set a target to bring it within the healthy range. We've
                  divided participants into categories based on BMI, each with a
                  unique role in the
                  <span className="bold-about"> Health Team India.</span>
                </p>
              </div>

              <div className="about-highlights d-flex">
                  <a href="https://drive.google.com/file/d/1km_HJkS3FFX90LBMDHKOBOqe0Xd7SJSV/view?usp=drive_link"><img src={google} alt="" /></a>
                  <a href= "https://apps.apple.com/in/app/nobesity-india/id6476114262"><img src={ios} alt="" /></a>
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            <div className="about-highlights card-font">
              <div className="info-about no-margin">
                <img src={star} alt="" className="img-fluid" />
                Individuals with BMI 25: Coach of Health Team India
              </div>
              <br />
              <div className="info-about no-margin">
                <img src={star} alt="" className="img-fluid" />
                26-30: Captain of Health Team India
              </div>
              <br />
              <div className="info-about no-margin">
                <img src={star} alt="" className="img-fluid" />
                31-35: Vice Captain of Health Team India
              </div>
              <br />
              <div className="info-about no-margin">
                <img src={star} alt="" className="img-fluid" />
                36-40: Best All-Rounder of Health Team India
              </div>
              <br />
              <div className="info-about no-margin">
                <img src={star} alt="" className="img-fluid" />
                41-45: Best Player of Health Team India
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
