import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Tabs } from "antd";
import calender from "../assets/img/icons/calender.svg";

export default function Events() {
  const onChange = (key) => {
    console.log(key);
  };

  const CustomCard = ({ date, title, description, colSize, br }) => {
    console.log(br);
    return (
      <div
        className="col-lg-4 d-flex align-items-center mt-4"
        data-aos="fade-right"
        data-aos-delay="100"
      >
        <div className="blog-box event-box" style={{ width: "100%" }}>
          <div className="about-highlights blog-details">
            <div
              className={`d-flex align-items-center event-date col-lg-${colSize}`}
            >
              <img src={calender} className="img-fluid" alt="" />
              <span>{date}</span>
            </div>

            <h4 className="mt-3">{title}</h4>
            <p>
              {description}{" "}
              {br === true ? (
                <>
                  <br />
                  &nbsp;
                </>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const items = [
    {
      key: "1",
      label: "All events",
      children: (
        <div className="row ">
          <CustomCard
            date="Dec 10, 2023"
            title="Awareness Campaign"
            description="Unite against obesity - Join the nationwide awareness campaign."
            colSize={5}
          />
          <CustomCard
            date="Dec 21 2023 to Jan 21 Jan"
            title="Pre-Registration"
            description="Secure your spot early - Pre-register for NOBESITY."
            colSize={9}
            br={true}
          />
          <CustomCard
            date="Jan. 22-2024 TO 28 Feb 2024"
            title="Registration"
            description="Officially join NOBESITY and kickstart your wellness journey."
            colSize={9}
            br={true}
          />
          <CustomCard
            date="March 4, 2024"
            title="World obesity day"
            description="Celebration+ 60 day Challenge launch"
            colSize={6}
            br={true}
          />
          <CustomCard
            date="Throughout the Challenge"
            title="Daily Uploads"
            description="Share your progress daily, inspiring others and tracking your success."
            colSize={8}
            br={false}
          />

          <CustomCard
            date="Jan 26, 2024"
            title="Republic Day Celebration"
            description="Sing the national anthem online - Earn 100 points!"
            colSize={5}
            br={true}
          />

          <CustomCard
            date="Post 4th March"
            title="Health Talks and Online Training Programs "
            description="Every Sunday Post 18th Feb 
            Join weekly sessions to learn and earn extra points every Sunday."
            colSize={6}
            br={false}
          />

          <CustomCard
            date="Mar 4, 2024"
            title="World Obesity Day Celebration"
            description="Celebrate global awareness - Earn 50 points!"
            colSize={5}
            br={true}
          />

          <CustomCard
            date="Mar 18, 2024"
            title="Revalidation of BMI"
            description="Ensure your progress is on track with a revalidation of your BMI."
            colSize={5}
            br={false}
          />

          <CustomCard
            date="5 June - 8 June"
            title="Final BMI Validation"
            description="Complete your journey - Final validation of your BMI."
            colSize={6}
            br={true}
          />

          <CustomCard
            date="To be Announced"
            title="Winners Announcement and Rewards Ceremony"
            description="Join the online ceremony for the big reveal and rewards distribution."
            colSize={5}
            br={false}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Upcoming",
      children: (
        <div className="row ">
          <CustomCard
            date="Dec 10, 2023"
            title="Awareness Campaign"
            description="Unite against obesity - Join the nationwide awareness campaign."
            colSize={5}
          />
          <CustomCard
            date="Dec 21 2023 to Jan 21 Jan"
            title="Pre-Registration"
            description="Secure your spot early - Pre-register for NOBESITY."
            colSize={9}
            br={true}
          />
          <CustomCard
            date="Jan. 22-2024 TO 28 Feb 2024"
            title="Registration"
            description="Officially join NOBESITY and kickstart your wellness journey."
            colSize={9}
            br={true}
          />
          <CustomCard
            date="March 4, 2024"
            title="World obesity day"
            description="Celebration+ 60 day Challenge launch"
            colSize={6}
            br={true}
          />
          <CustomCard
            date="Throughout the Challenge"
            title="Daily Uploads"
            description="Share your progress daily, inspiring others and tracking your success."
            colSize={8}
            br={false}
          />

          <CustomCard
            date="Jan 26, 2024"
            title="Republic Day Celebration"
            description="Sing the national anthem online - Earn 100 points!"
            colSize={5}
            br={true}
          />

          <CustomCard
            date="Post 4th March"
            title="Health Talks and Online Training Programs "
            description="Every Sunday Post 18th Feb 
            Join weekly sessions to learn and earn extra points every Sunday."
            colSize={6}
            br={false}
          />

          <CustomCard
            date="Mar 4, 2024"
            title="World Obesity Day Celebration"
            description="Celebrate global awareness - Earn 50 points!"
            colSize={5}
            br={true}
          />

          <CustomCard
            date="Mar 18, 2024"
            title="Revalidation of BMI"
            description="Ensure your progress is on track with a revalidation of your BMI."
            colSize={5}
            br={false}
          />

          <CustomCard
            date="5 June - 8 June"
            title="Final BMI Validation"
            description="Complete your journey - Final validation of your BMI."
            colSize={6}
            br={true}
          />

          <CustomCard
            date="To be Announced"
            title="Winners Announcement and Rewards Ceremony"
            description="Join the online ceremony for the big reveal and rewards distribution."
            colSize={5}
            br={false}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Recent",
      children: (
        <div className="row ">
          <CustomCard
            date="Dec 10, 2023"
            title="Awareness Campaign"
            description="Unite against obesity - Join the nationwide awareness campaign."
            colSize={5}
          />
          <CustomCard
            date="Dec 21 2023 to Jan 21 Jan"
            title="Pre-Registration"
            description="Secure your spot early - Pre-register for NOBESITY."
            colSize={9}
            br={true}
          />
          <CustomCard
            date="Jan. 22-2024 TO 28 Feb 2024"
            title="Registration"
            description="Officially join NOBESITY and kickstart your wellness journey."
            colSize={9}
            br={true}
          />
          <CustomCard
            date="March 4, 2024"
            title="World obesity day"
            description="Celebration+ 60 day Challenge launch"
            colSize={6}
            br={true}
          />
          <CustomCard
            date="Throughout the Challenge"
            title="Daily Uploads"
            description="Share your progress daily, inspiring others and tracking your success."
            colSize={8}
            br={false}
          />

          <CustomCard
            date="Jan 26, 2024"
            title="Republic Day Celebration"
            description="Sing the national anthem online - Earn 100 points!"
            colSize={5}
            br={true}
          />

          <CustomCard
            date="Post 4th March"
            title="Health Talks and Online Training Programs "
            description="Every Sunday Post 18th Feb 
            Join weekly sessions to learn and earn extra points every Sunday."
            colSize={6}
            br={false}
          />

          <CustomCard
            date="Mar 4, 2024"
            title="World Obesity Day Celebration"
            description="Celebrate global awareness - Earn 50 points!"
            colSize={5}
            br={true}
          />

          <CustomCard
            date="Mar 18, 2024"
            title="Revalidation of BMI"
            description="Ensure your progress is on track with a revalidation of your BMI."
            colSize={5}
            br={false}
          />

          <CustomCard
            date="5 June - 8 June"
            title="Final BMI Validation"
            description="Complete your journey - Final validation of your BMI."
            colSize={6}
            br={true}
          />

          <CustomCard
            date="To be Announced"
            title="Winners Announcement and Rewards Ceremony"
            description="Join the online ceremony for the big reveal and rewards distribution."
            colSize={5}
            br={false}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <Navbar />
      <section id="hero" className="d-flex align-items-center about-page event-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-center">
                <h1 className="title-detail">Events</h1>
              </div>

              <p className="title-para text-center"></p>
              <div className="text-center">
                <a href="/" className="details-page">
                  <span className="home"> Home / </span>
                  <span className="detail"> Events </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg event-section">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <Tabs defaultActiveKey="1" items={items} onChange={onChange}  />

              {/* <div className="about-highlights">
                <h4>Terms and Conditions</h4>

                <p>
                  These Terms and Conditions ("Terms") govern your participation
                  in the health awareness program offered by Stallion
                  Corporation under the brand name "Nobesity-India fights with
                  obesity." By participating in the program, you agree to comply
                  with these Terms. Please read these Terms carefully before
                  enrolling in the Nobesity-India fights with obesity program.
                </p>

                <p>
                  1. Eligibility: To participate in the Nobesity-India fights
                  with obesity program, you must be at least 18 years old. By
                  enrolling in the program, you confirm that you meet this
                  eligibility requirement.
                </p>

                <p>
                  2. Enrollment: To enroll in the Nobesity-India fights with
                  obesity program, you are required to provide accurate and
                  complete information, including but not limited to your name,
                  contact details, age, and health-related information. You
                  agree to update your information promptly to ensure its
                  accuracy.
                </p>
                <p>
                  3. Health Information: You acknowledge that the health
                  awareness program may involve the collection and analysis of
                  health-related information, including Body Mass Index (BMI)
                  and fitness goals. This information will be used solely for
                  the purpose of tailoring program content and recommendations
                  to your individual needs
                </p>
                <p>
                  4. Program Content: The health awareness program content,
                  including educational materials and recommendations, is
                  provided for informational purposes only. It is not intended
                  as a substitute for professional medical advice, diagnosis, or
                  treatment. You should consult with your healthcare provider
                  before making any changes to your health or fitness regimen.
                  The methods use by any individual to reduce BMI will be
                  completely his/her choice and it should be strictly under
                  supervision or advise of registered medical practitioner or
                  registered Dietician or Qualified health trainer only, If any
                  health losses or suffering happens during process of reducing
                  BMI then Nobesity-India fights with Obesity will not be
                  responsible for it, individual himself/herself will be
                  completely responsible for such losses or suffering.
                </p>
                <p>
                  The rewards given are just to motivate individuals to take the
                  first step towards health and not to create any mishappening
                  or misconduct as each individual is dealing with their own
                  health. The health information given by individual should be
                  precise and no malpractice or wrong information will be
                  entertain at all during this awareness program. The individual
                  found to be doing so will be immediately terminated from
                  awareness program and competition.
                </p>
                <p>
                  5. Communication: By enrolling in the Nobesity-India fights
                  with obesity program, you consent to receive communications
                  related to the program, including updates, rewards, and
                  educational materials. You may opt out of certain
                  communications, such as marketing emails, by following the
                  provided opt-out instructions.
                </p>
                <p>
                  6. Privacy: Your participation in the Nobesity-India fights
                  with obesity program is subject to our Privacy Policy. Please
                  review the Privacy Policy to understand how we collect, use,
                  and protect your personal information.
                </p>
                <p>
                  7. Rewards: Nobesity -India fights with Obesity may offer
                  rewards to participants who achieve specified health and
                  fitness goals.And this completely system based
                  selection-system would randomly select candidate with drastic
                  comparative change in BMI in specific time period i.e.,
                  maximum change in BMI in association with minimum time. This
                  reward system is just to boost the commencement of India's
                  biggest health awareness campaign. The rewards are not the
                  goal of this event. The nature and availability of rewards are
                  subject to change at Nobesity-India fights with obesity
                  discretion. Rewards are not transferable, redeemable for cash,
                  or exchangeable.
                </p>

                <p>
                  8. Termination: Nobesity-India fights with obesity reserves
                  the right to terminate or suspend your participation in the
                  Nobesity-India fights with obesity program at any time and for
                  any reason, including, but not limited to, violation of these
                  Terms or misuse of the program.
                </p>
                <p>
                  9. Changes to Terms: Nobesity-India fights with obesity may
                  modify these Terms at any time without prior notice. The
                  effective date of the Terms will be revised accordingly.
                  Continued participation in the Nobesity-India fights with
                  obesity program constitutes acceptance of the modified Terms.
                </p>
                <p>
                  10. Governing Law: These Terms are governed by and construed
                  in accordance with the laws of Vadodara Gujarat only.. Any
                  disputes arising out of or in connection with these Terms
                  shall be resolved through arbitration in accordance with the
                  rules of Arbitration Institution selected by Nobesity -India
                  fights with Obesity, and judgment upon the award rendered by
                  the arbitrator(s) may be entered in court having jurisdiction
                  thereof.
                </p>
                <p>
                  By enrolling in the Nobesity-India fights with obesity
                  program, you acknowledge that you have read, understood, and
                  agree to these Terms and Conditions. If you do not agree to
                  these Terms, please do not participate in the Nobesity-India
                  fights with obesity program.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
