import React from "react";
import man from "../assets/img/man.svg";
import GetStartedBtn from "./GetStartedBtn";
import LearnMoreBtn from "./LearnMoreBtn";

export default function JoinMovement() {
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="row join-now">
          <div className="col-lg-5">
            <img src={man} className="img-fluid join-man" alt="" />
          </div>

          <div className="col-lg-7">
            <h1>Join the Movement ✊</h1>
            <p className="join-para">
              NOBESITY-INDIA FIGHTS WITH OBESITY URGES ALL INDIAN YOUTH TO
              TIGHTEN YOUR SHOES AND LETS FIGHT WITH OBESITY for prosprerity of
              our country.
            </p>
            <GetStartedBtn />
            <LearnMoreBtn />
          </div>
        </div>
      </div>
    </section>
  );
}
