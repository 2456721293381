import React from 'react'
import getStarted from "../assets/img/icons/getstart.svg";

export default function GetStartedBtn() {
  return (
    <a href="/login" className="btn-get-started scrollto">
    Get Started
    <img
      src={getStarted}
      alt="gs"
      className="img-fluid"
    />
  </a>
  )
}
