import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Term() {
  return (
    <>
      <Navbar />
      <section id="hero" className="d-flex align-items-center about-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-center">
                <h1 className="title-detail">Terms and Conditions</h1>
              </div>

              <p className="title-para text-center"></p>
              <div className="text-center">
                <a href="/" className="details-page">
                  <span className="home"> Home / </span>
                  <span className="detail"> Terms and Conditions </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="about-highlights">
                <h4>Terms and Conditions</h4>

                <p>
                  These Terms and Conditions ("Terms") govern your participation
                  in the health awareness program offered by Stallion
                  Corporation under the brand name "Nobesity-India fights with
                  obesity." By participating in the program, you agree to comply
                  with these Terms. Please read these Terms carefully before
                  enrolling in the Nobesity-India fights with obesity program.
                </p>

                <p>
                  1. Eligibility: To participate in the Nobesity-India fights
                  with obesity program, you must be at least 18 years old. By
                  enrolling in the program, you confirm that you meet this
                  eligibility requirement.
                </p>

                <p>
                  2. Enrollment: To enroll in the Nobesity-India fights with
                  obesity program, you are required to provide accurate and
                  complete information, including but not limited to your name,
                  contact details, age, and health-related information. You
                  agree to update your information promptly to ensure its
                  accuracy.
                </p>
                <p>
                  3. Health Information: You acknowledge that the health
                  awareness program may involve the collection and analysis of
                  health-related information, including Body Mass Index (BMI)
                  and fitness goals. This information will be used solely for
                  the purpose of tailoring program content and recommendations
                  to your individual needs
                </p>
                <p>
                  4. Program Content: The health awareness program content,
                  including educational materials and recommendations, is
                  provided for informational purposes only. It is not intended
                  as a substitute for professional medical advice, diagnosis, or
                  treatment. You should consult with your healthcare provider
                  before making any changes to your health or fitness regimen.
                  The methods use by any individual to reduce BMI will be
                  completely his/her choice and it should be strictly under
                  supervision or advise of registered medical practitioner or
                  registered Dietician or Qualified health trainer only, If any
                  health losses or suffering happens during process of reducing
                  BMI then Nobesity-India fights with Obesity will not be
                  responsible for it, individual himself/herself will be
                  completely responsible for such losses or suffering.
                </p>
                <p>
                  The rewards given are just to motivate individuals to take the
                  first step towards health and not to create any mishappening
                  or misconduct as each individual is dealing with their own
                  health. The health information given by individual should be
                  precise and no malpractice or wrong information will be
                  entertain at all during this awareness program. The individual
                  found to be doing so will be immediately terminated from
                  awareness program and competition.
                </p>
                <p>
                  5. Communication: By enrolling in the Nobesity-India fights
                  with obesity program, you consent to receive communications
                  related to the program, including updates, rewards, and
                  educational materials. You may opt out of certain
                  communications, such as marketing emails, by following the
                  provided opt-out instructions.
                </p>
                <p>
                  6. Privacy: Your participation in the Nobesity-India fights
                  with obesity program is subject to our Privacy Policy. Please
                  review the Privacy Policy to understand how we collect, use,
                  and protect your personal information.
                </p>
                <p>
                  7. Rewards: Nobesity -India fights with Obesity may offer
                  rewards to participants who achieve specified health and
                  fitness goals.And this completely system based
                  selection-system would randomly select candidate with drastic
                  comparative change in BMI in specific time period i.e.,
                  maximum change in BMI in association with minimum time. This
                  reward system is just to boost the commencement of India's
                  biggest health awareness campaign. The rewards are not the
                  goal of this event. The nature and availability of rewards are
                  subject to change at Nobesity-India fights with obesity
                  discretion. Rewards are not transferable, redeemable for cash,
                  or exchangeable.
                </p>

                <p>
                  8. Termination: Nobesity-India fights with obesity reserves
                  the right to terminate or suspend your participation in the
                  Nobesity-India fights with obesity program at any time and for
                  any reason, including, but not limited to, violation of these
                  Terms or misuse of the program.
                </p>
                <p>
                  9. Changes to Terms: Nobesity-India fights with obesity may
                  modify these Terms at any time without prior notice. The
                  effective date of the Terms will be revised accordingly.
                  Continued participation in the Nobesity-India fights with
                  obesity program constitutes acceptance of the modified Terms.
                </p>
                <p>
                  10. Governing Law: These Terms are governed by and construed
                  in accordance with the laws of Vadodara Gujarat only.. Any
                  disputes arising out of or in connection with these Terms
                  shall be resolved through arbitration in accordance with the
                  rules of Arbitration Institution selected by Nobesity -India
                  fights with Obesity, and judgment upon the award rendered by
                  the arbitrator(s) may be entered in court having jurisdiction
                  thereof.
                </p>
                <p>
                  By enrolling in the Nobesity-India fights with obesity
                  program, you acknowledge that you have read, understood, and
                  agree to these Terms and Conditions. If you do not agree to
                  these Terms, please do not participate in the Nobesity-India
                  fights with obesity program.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
