import React, { useEffect, useState } from "react";
import loginCh from "../../assets/img/icons/loginCheck.svg";
import banner1 from "../../assets/img/side1.svg";
import takePledgeImg from "../../assets/img/icons/pledge.svg";
import Select from "react-select";
import csc from "country-state-city";
import Certificate from "./Certificate";
import { DatePicker } from "antd";

import axios from "axios";
import i18next from "i18next";

export default function TakePledge() {
  const [showPledge, setShowPledge] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");

  const [dob, setDob] = useState("");
  const [pin, setPin] = useState("");
  const [cerLang, setCerLang] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [dbState, setDbState] = useState("");

  const validateMobileNumber = (value) => {
    const isValid = /^\d{10}$/.test(value);
    if (!isValid) {
      setMobileNumberError("Please enter a valid 10-digit mobile number.");
    } else {
      setMobileNumberError("");
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);

    if (!isValid) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  function trimUrl(url) {
    // Check if the URL contains a query string
    const queryStringIndex = url.indexOf("?");

    if (queryStringIndex !== -1) {
      // Remove the query string and everything after it
      return url.substring(0, queryStringIndex);
    } else {
      // URL doesn't have a query string
      return url;
    }
  }

  function extractUUIDFromUrl(url) {
    // Define a regular expression to match UUID patterns
    const uuidRegex = /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}?)/i;

    // Use match to find the first UUID in the URL
    const match = url.match(uuidRegex);

    // Return the matched UUID or null if not found
    return match ? match[0] : null;
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const selectedFile = e.target.files[0];

    // Check the file type
    if (selectedFile) {
      try {
        // Make a GET request to the API endpoint to obtain the pre-signed URL
        const response = await fetch(
          "https://nv5k51rvoc.execute-api.ap-south-1.amazonaws.com/prod/generateUploadURL"
        );

        console.log("response", response);

        const data = await response.json();

        console.log("data", data);
        const preSignedURL = data.presignedUrl;

        const trimmedUrl = trimUrl(preSignedURL);
        const extractedUUID = extractUUIDFromUrl(trimmedUrl);

        console.log("extractedUUID", extractedUUID);
        const previewURL = URL.createObjectURL(selectedFile);
        await fetch(preSignedURL, {
          method: "PUT",
          body: selectedFile,
        });

        // Now you can use the preSignedURL as a CDN for the uploaded file
        console.log("File uploaded successfully:", preSignedURL);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    // Update the state with the selected file
    setSelectedFile(file);

    // Update the label text with the file name
    const label = document.querySelector(".file-input__label span");
    if (label) {
      label.textContent = file ? file.name : "Upload latest photo";
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesData = csc.getAllCountries();
        console.log("countriesData", countriesData);
        const updatedCountries = countriesData.map((country) => ({
          label: country.name,
          value: country.id, // Change this to the appropriate property for country value
          ...country,
        }));
        setCountries(updatedCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    // Set default country and states
    if (countries.length > 0) {
      // Find the country with the name "India"
      const indiaCountry = countries.find(
        (country) => country.name === "India"
      );
      if (indiaCountry) {
        const defaultStates = updatedStates(indiaCountry.value);

        console.log("defaultStates", defaultStates);
        setStates(defaultStates);
      }
    }
  }, [countries]);

  useEffect(() => {
    // Set default state and cities
    if (selectedState && selectedState !== "") {
      const defaultCities = updatedCities(selectedState);
      setCities(defaultCities);
    }
  }, [selectedState]);

  const updatedStates = (countryId) =>
    csc.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.id, // Change this to the appropriate property for state value
      ...state,
    }));

  const updatedCities = (stateId) =>
    csc.getCitiesOfState(stateId).map((city) => ({
      label: city.name,
      value: city.id, // Change this to the appropriate property for city value
      ...city,
    }));

  const handleStateChange = (e) => {
    const selectedStateId = e.target.value;
    setSelectedState(selectedStateId);

    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setDbState(selectedLabel);
    

    const stateCities = updatedCities(selectedStateId);
    setCities(stateCities);
  };

  const handleDateChange = (selectedDate, dateInString) => {
    // Update the state with the selected date
    console.log(dateInString, 99);
    setDob(dateInString);
  };


  const submitPledge = async (e) => {
    e.preventDefault();

    try {
      const query = `mutation MyMutation(
        $email: AWSEmail!,
        $fullName: String,
        $certificateLanguage: String, 
        $city: String,
        $dob: AWSDate, 
        $gendert: String, 
        $mobileNumber: String , 
        $pincode: String, 
        $state: String) {
        createTakePledge(input: {
          email: $email, 
          fullName: $fullName,
          certificateLanguage: $certificateLanguage, 
          city: $city, 
          dob: $dob, 
          gendert: $gendert, 
          mobileNumber: $mobileNumber, 
          pincode: $pincode, 
          state: $state}) {
          email
        }
      }
        `;

      const variables = {
        email: email,
        fullName: username,
        mobileNumber: mobileNumber,
        certificateLanguage: cerLang,
        city: city,
        dob: dob,
        gendert: gender,
        mobileNumber: mobileNumber,
        pincode: pin,
        state: dbState,
      };

      await axios.post(
        "https://5kz3niiwirg2xcz5hiwvw4tpae.appsync-api.ap-south-1.amazonaws.com/graphql",
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "da2-qpy4hr4mejbufnkfd3quqkacym",
          },
        }
      );
      setShowPledge(true);
    } catch (err) {
      console.log(err);
    }
  };

  const translations = {
    en: {
      certificateLanguage: "Certificate Language*",
      exercisePledge: "To exercise/yoga daily for at least 30-45 minutes.",
      healthyFoodPledge: "To eat healthy food and avoid junk food.",
      sleepPledge: "To sleep for at least 7-8 hours daily.",
      motivatePledge:
        "To motivate friends, family members, and people near me to cultivate a healthy lifestyle.",
      supportPledge: "To support NOBESITYINDIA's fight against obesity.",
    },
    hi: {
      certificateLanguage: "सर्टिफिकेट भाषा*",
      exercisePledge: "रोजाना कम से कम 30-45 मिनट के लिए योग/व्यायाम करना।",
      healthyFoodPledge: "स्वस्थ खाना खाना और जंक फ़ूड से बचना।",
      sleepPledge: "रोजाना कम से कम 7-8 घंटे सोना।",
      motivatePledge:
        "दोस्तों, परिवार के सदस्यों और मेरे पास रहने वाले लोगों को स्वस्थ जीवनशैली को बढ़ावा देने के लिए प्रेरित करना।",
      supportPledge: "ओबेसिटी के खिलाफ NOBESITYINDIA के साथ समर्थन करना।",
    },
    gu: {
      certificateLanguage: "સર્ટિફિકેટ ભાષા*",
      exercisePledge: "દરરોજ ઓછામાં ઓછ 30-45 મિનિટ માટે યોગ/વ્યાયામ કરવો.",
      healthyFoodPledge: "સર્જન આપતા હોય અને જંક ફૂડ સેવવો.",
      sleepPledge: "રોજના ઓછામાં ઓછ 7-8 કલાકો સુધી ઊભા રહો.",
      motivatePledge:
        "મિત્રો, પરિવારના સભ્યો અને મારા આસપાસના લોકોને આરોગ્યદાયક જીવનશૈલીને બઢાવવા માટે પ્રેરિત કરવું.",
      supportPledge: "ઓબેસિટીની લડાઇને સાથ આપવાનો સમર્થન કરવો NOBESITYINDIA.",
    },
  };

  const [cerLangT, setCerLangL] = useState("en");

  const handleLanguageChange = (selectedLang) => {
    setCerLangL(selectedLang);
  };

  const translate = (key) => {
    const languageTranslations = translations[cerLangT];
    if (languageTranslations) {
      return languageTranslations[key] || key;
    } else {
      return key;
    }
  };

  const handleLang = (e) => {
    setCerLang(e.target.value);
    setCerLangL(e.target.value);
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handleCity = (e) => {
    console.log(e.target.value);
    setCity(e.target.value);
  };

  const handlePin = (e) => {
    setPin(e.target.value);
  };

  return (
    <>
      <section id="hero" className={`d-flex align-items-center pledge-form `}>
        <div className="container">
          {showCertificate ? (
            <>
              <div className="row">
                <div
                  className="d-flex justify-content-center mt-3"
                  style={{ fontSize: "30px", textAlign: "center" }}
                >
                  🤝 <br /> Thank you for joining with us
                </div>

                <div style={{ padding: "150px", marginTop: "-100px" }}>
                  <div
                    style={{
                      border: "20px solid #fff",
                      borderRadius: "70px",
                    }}
                  >
                    <Certificate
                      showInline={true}
                      selectedFile={selectedFile}
                      name={username}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {showPledge ? (
                  <>
                    <div className="row pledge-field">
                      <div className="about-highlights login-wraper pledge-res">
                        <h4>
                          Read pledge{" "}
                          <img
                            src={takePledgeImg}
                            className="img-fluid"
                            alt=""
                          />
                        </h4>

                        <p>
                          Our ultimate goal is a fit youth, a fit India, and a
                          prosperous India.
                        </p>
                      </div>

                      <div className="form-group col-md-12">
                        <div className="field">
                          <select
                            class="form-select login-select"
                            placeholder="Select"
                            defaultValue={cerLang}
                            onChange={(e) =>
                              handleLanguageChange(e.target.value)
                            }
                          >
                            <option disabled selected>
                              Certificate Language
                            </option>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                            <option value="gu">Gujarati</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="about-highlights">
                          <p className="pledge-p">
                            <img
                              src={takePledgeImg}
                              className="img-fluid"
                              alt=""
                            />{" "}
                            <span
                              className="bold-about"
                              style={{ paddingRight: "0px" }}
                            >
                              {translate("exercisePledge")}
                              {/* To exercise/ yoga daily atleast for 30-45 mints. */}
                            </span>
                          </p>
                          <p className="pledge-p">
                            <img
                              src={takePledgeImg}
                              className="img-fluid"
                              alt=""
                            />{" "}
                            <span
                              className="bold-about"
                              style={{ paddingRight: "0px" }}
                            >
                              {translate("healthyFoodPledge")}

                              {/* To Eat healthy Food and Avoid junk food */}
                            </span>
                          </p>
                          <p className="pledge-p">
                            <img
                              src={takePledgeImg}
                              className="img-fluid"
                              alt=""
                            />{" "}
                            <span
                              className="bold-about"
                              style={{ paddingRight: "0px" }}
                            >
                              {translate("sleepPledge")}
                              {/* To sleep for atleast 7-8 hours daily. */}
                            </span>
                          </p>
                          <p className="pledge-p mt-4">
                            <img
                              src={takePledgeImg}
                              className="img-fluid"
                              alt=""
                            />{" "}
                            <span
                              className="bold-about"
                              style={{ paddingRight: "0px" }}
                            >
                              {translate("motivatePledge")}
                              {/* To Motivate friends, family members and people
                              Near me to Cultivate healthy lifestyle. */}
                            </span>
                          </p>

                          <p className="pledge-p mt-4">
                            <img
                              src={takePledgeImg}
                              className="img-fluid"
                              alt=""
                            />{" "}
                            <span
                              className="bold-about"
                              style={{ paddingRight: "0px" }}
                            >
                              {translate("supportPledge")}
                              {/* To support NOBESITYINDIA fight with obesity. */}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="text-center d-flex mt-5 pb-5 i-pledge">
                        <button
                          type="submit"
                          onClick={() => {
                            setShowCertificate(true);
                            setShowPledge(false);
                          }}
                          className="pledge-btn i-pledge-btn mt-5"
                        >
                          I pledge
                          <img
                            src={takePledgeImg}
                            className="img-fluid"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <form
                    id="registrationForm"
                    role="form"
                    className="php-email-form login-ff"
                    onSubmit={submitPledge}
                  >
                    <div className="row pledge-field">
                      <div className="about-highlights login-wraper pledge-res">
                        <h4>
                          Take A Pledge{" "}
                          <img
                            src={takePledgeImg}
                            className="img-fluid"
                            alt=""
                          />
                        </h4>

                        <p>
                          Our ultimate goal is a fit youth, a fit India, and a
                          prosperous India.
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field">
                          <input
                            type="text"
                            required
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <label>Full name*</label>
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field">
                          <input
                            type="file"
                            class="file-input__input"
                            required
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png, .heic"
                          />
                          <label class="pledge-upload">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.3643 15.3105C10.6901 15.6431 11.0791 15.9074 11.5084 16.0878C11.9377 16.2681 12.3986 16.3611 12.8643 16.3611C13.3299 16.3611 13.7909 16.2681 14.2201 16.0878C14.6494 15.9074 15.0384 15.6431 15.3643 15.3105M15.8643 11.3105H15.8743M9.86426 11.3105H9.87426M5.86426 7.31055H6.86426C7.39469 7.31055 7.9034 7.09983 8.27847 6.72476C8.65354 6.34969 8.86426 5.84098 8.86426 5.31055C8.86426 5.04533 8.96961 4.79098 9.15715 4.60344C9.34469 4.4159 9.59904 4.31055 9.86426 4.31055H15.8643C16.1295 4.31055 16.3838 4.4159 16.5714 4.60344C16.7589 4.79098 16.8643 5.04533 16.8643 5.31055C16.8643 5.84098 17.075 6.34969 17.45 6.72476C17.8251 7.09983 18.3338 7.31055 18.8643 7.31055H19.8643C20.3947 7.31055 20.9034 7.52126 21.2785 7.89633C21.6535 8.27141 21.8643 8.78011 21.8643 9.31055V18.3105C21.8643 18.841 21.6535 19.3497 21.2785 19.7248C20.9034 20.0998 20.3947 20.3105 19.8643 20.3105H5.86426C5.33382 20.3105 4.82512 20.0998 4.45004 19.7248C4.07497 19.3497 3.86426 18.841 3.86426 18.3105V9.31055C3.86426 8.78011 4.07497 8.27141 4.45004 7.89633C4.82512 7.52126 5.33382 7.31055 5.86426 7.31055Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <span>
                              {selectedFile
                                ? selectedFile.name
                                : "Upload latest photo"}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field">
                          <select
                            class="form-select login-select"
                            placeholder="Select"
                            required
                            onChange={handleGender}
                          >
                            <option value="" disabled selected>
                              Gender*
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field login-date">
                          <DatePicker
                            placeholder="Date Of Birth"
                            onChange={handleDateChange}
                            disabledDate={(d) => !d || d.isAfter(new Date())}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="field">
                          <input type="number" required onChange={handlePin} />
                          <label>Pin Code*</label>
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field">
                          <select
                            className="form-select login-select"
                            onChange={handleStateChange}
                            required
                          >
                            <option value="" disabled selected>
                              Select State*
                            </option>
                            {states.map((state) => (
                              <option key={state.value} value={state.value}>
                                {state.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="field">
                          <select
                            className="form-select login-select"
                            required
                            onChange={handleCity}
                          >
                            <option value="" disabled selected>
                              Select City*
                            </option>
                            {cities?.map((city) => (
                              <option key={city.value} value={city.label}>
                                {city.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field">
                          <select
                            class="form-select login-select"
                            placeholder="Select"
                            required
                            onChange={handleLang}
                          >
                            <option value="" disabled selected>
                              Certificate Language*
                            </option>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                            <option value="gu">Gujarati</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field">
                          <input
                            type="text"
                            maxLength={10}
                            required
                            value={mobileNumber}
                            onChange={(e) => {
                              setMobileNumber(e.target.value);
                              validateMobileNumber(e.target.value);
                            }}
                          />
                          <label>Mobile Number*</label>
                        </div>
                        {mobileNumberError && (
                          <p style={{ color: "red" }}>{mobileNumberError}</p>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <div className="field">
                          <input
                            type="text"
                            required
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              validateEmail(e.target.value);
                            }}
                          />
                          <label>Email Address*</label>
                        </div>
                        {emailError && (
                          <p style={{ color: "red" }}>{emailError}</p>
                        )}
                      </div>
                    </div>

                    <div className="form-group col-md-12 login-gr pledge-field">
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingBottom: "20px" }}
                      >
                        {/* <img
                          src={loginCh}
                          className="img-fluid"
                          alt=""
                          style={{ marginLeft: "-15px" }}
                        /> */}
                        <input
                          type="checkbox"
                          className="login-checkbox"
                          required
                        />
                        <div>
                          <span style={{ fontSize: "12px" }}>
                            Details given above can be used to create a Nobesity
                            on my behalf.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="text-center d-flex pb-5 pledge-field ">
                      <button type="submit" className="pledge-btn">
                        Take pledge
                        <img
                          src={takePledgeImg}
                          className="img-fluid"
                          alt=""
                          style={{ paddingLeft: "5px" }}
                        />
                      </button>
                    </div>
                  </form>
                )}
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img d-flex align-items-center hide-pledge-img"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img src={banner1} className="img-fluid" alt="" />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
