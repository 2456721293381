import React from "react";
import h1 from "../assets/img/icons/Health/h1.svg"
import h2 from "../assets/img/icons/Health/h2.svg"
import h3 from "../assets/img/icons/Health/h3.svg"
import h4 from "../assets/img/icons/Health/h4.svg"
import h5 from "../assets/img/icons/Health/h5.svg"
import h6 from "../assets/img/icons/Health/h6.svg"

export default function SignHealth() {
  return (
    <section id="cta" className="cta-tips">
      <div className="container" data-aos="zoom-in">
        <div className="row join-now">
          <div className="col-lg-12 text-center">
            <h1>Sign For healthy life style 🤩</h1>

            <div className="row service-tips">
              <div
                className="col-xl-4 col-md-4 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src={h1}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>
                    Poor Sleep can disturb metabolism! AIM for 7-9 hours a day!
                  </p>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-4 d-flex align-items-stretch mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box">
                  <div className="icon">
                    <div className="icon">
                      <img
                        src={h2}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <p>
                    A balanced diet includes fruit, vegetables and whole grains,
                    not sugary drinks, high- fat Items, or Processed foods
                  </p>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-4 d-flex align-items-stretch mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="icon-box">
                  <div className="icon">
                    <div className="icon">
                      <img
                        src={h3}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <p>Don’t let the screentime, take a toll on your fitness!</p>
                </div>
              </div>
            </div>

            <div className="row service-tips-nomargin">
              <div
                className="col-xl-4 col-md-4 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src={h4}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>No one Ever Regretted working out!</p>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-4 d-flex align-items-stretch mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box">
                  <div className="icon">
                    <div className="icon">
                      <img
                        src={h5}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <p>Calorie Intake – Exercise Requirement</p>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-4 d-flex align-items-stretch mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="icon-box">
                  <div className="icon">
                    <div className="icon">
                      <img
                        src={h6}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <p>
                    Drink more water, It's not always hunger that causes stomach
                    noises.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
