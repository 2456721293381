import React from 'react'
import lm from "../assets/img/icons/arrow.svg";

export default function LearnMoreBtn() {
  return (
    <a href="https://coach.nobesityindia.com" className="btn-get-started scrollto learn-more-btn">
    Learn More
    <img
      src={lm}
      alt="lm"
      className="img-fluid"
    />
  </a>
  )
}
