import React from "react";
import footIC from "../assets/img/footeric.svg";
import google from "../assets/img/goggle.png";
import ios from "../assets/img/appstore.png";
import arrow from "../assets/img/icons/greenarrow.svg";
import flag from "../assets/img/icons/ind.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact">
                <img src={footIC} alt="" />
                <p>
                  Our ultimate goal is a fit youth, a fit India, and a
                  prosperous India. Join the Nobesity movement with full vigor
                  and contribute to making India healthier and stronger.
                </p>

                <div className="about-highlights d-flex">
                <a href="https://drive.google.com/file/d/1km_HJkS3FFX90LBMDHKOBOqe0Xd7SJSV/view?usp=drive_link"><img src={google} alt="" /></a>
                <a href= "https://apps.apple.com/in/app/nobesity-india/id6476114262"><img src={ios} alt="" style={{ paddingLeft: "10px" }} /></a>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <div className="d-flex foot-link align-items-center">
                  <div className="footer-line"></div>
                  <h4>Quick Links</h4>
                </div>

                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/#">Blogs</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/refund-policy">Refund Policy</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/t&c">T&C</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/contact">Contact us</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <div className="d-flex foot-link align-items-center">
                  <div className="footer-line"></div>
                  <h4>Contact Details</h4>
                </div>

                <ul>
                  <li>
                    <div>
                      <div>
                        <span className="badge footer-badge">Email</span>
                      </div>
                      <div className="">support@nobesityIndia.com</div>
                      <div className="">mr.nobesity@nobesityindia.com</div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="badge footer-badge">
                        {" "}
                        Social handles
                      </span>
                    </div>
                  </li>
                  <li className="soc-ic">
                    <div className="social-links">
                      <a href="https://www.instagram.com/nobesity.india/" target="_blank" className="instagram">
                        <i className="bx bxl-instagram"></i>
                      </a>
                      <a href="https://twitter.com/NobesityIndia" target="_blank" className="twitter">
                        <i className="bx bxl-twitter"></i>
                      </a>
                      <a href="https://www.facebook.com/people/Nobesity-India/61554125459769/" className="facebook"
                      target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>

                      <a href="https://www.linkedin.com/company/nobesityindia/"   target="_blank" className="linkedin">
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <div className="d-flex foot-link align-items-center">
                  <div className="footer-line"></div>
                  <h4>Newsletter</h4>
                </div>
                <p style={{ marginTop: "20px", fontSize: "13px" }} className="news-p">
                Being overweight is more than a mere struggle. It comes with serious health concerns. Gear up for a journey that combats obstacles, with our dedicated team, and get guidance from trusted health professionals. Keep up to date with our latest articles, and news. All you need to do is SUBSCRIBE! Don’t worry we wont spam your inbox!
                </p>

                <div className="social-links col-lg-3 mt-3 input-form">
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                    <div className="input-group-append">
                      <img src={arrow} alt="" />
                    </div>
                  </div>
                </div>

                <div className="mt-4 d-flex vande-b">
                  <div>
                    <img src={flag} alt="" />
                  </div>
                  <div>
                    <p>
                      Vande Mataram, Main hu Mr. Nobesity, Aao banate hai INDIA
                      ko fit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-bottom clearfix d-flex text-center">
          <div className="copyright">
            &copy; Copyright 2024. All Rights Reserved &nbsp;
            <strong>
              <span>Nobesity</span>
            </strong>
          </div>
        </div>
      </footer>

      <div id="preloader"></div>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
