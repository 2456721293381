import React, { useState } from "react";

export default function WhyUs() {
  const [readMore, setReadMore] = useState(false);

  return (
    <section id="why-us" className="why-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
            <div className="content">
              <div className="d-flex">
                <div className="side-1-line"></div>
                <div className="about-nobesity">
                  <p>Health Crisis</p>
                  <h3>
                    Battling Obesity
                    <span className="org-text"> India's Health Imperative</span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="accordion-list">
              <div className="about-highlights">
                <h4>The Urgency</h4>

                <p>
                  India is on the path to prosperity and becoming an emerging
                  superpower, guided by the vision of our Honorable Prime
                  Minister, Narendra Modi. As we all know, the majority of
                  India's population falls within the youth age group,
                  approximately 25-45 years.{" "}
                  {readMore ? (
                    ""
                  ) : (
                    <span
                      className="read-more"
                      onClick={() => setReadMore(true)}
                    >
                      Read more
                    </span>
                  )}
                </p>

                {readMore ? (
                  <div>
                    <p>
                      The Indian youth plays a pivotal role in making India
                      prosperous. However, if this youth is not physically fit,
                      it could pose a hindrance to India's journey towards
                      becoming a superpower. Unfortunately, a significant number
                      of Indian youth are succumbing to obesity due to their
                      lifestyle choices, leading to an increase in lifestyle
                      diseases among them. These diseases significantly reduce
                      the efficiency and productivity of our youth.{" "}
                    </p>

                    <p>
                      It is an urgent and alarming situation that requires
                      immediate recognition. We must unite and address this new
                      pandemic with the same level of determination and unity as
                      we demonstrated against COVID-19.{" "}
                      <span
                        className="read-less"
                        onClick={() => setReadMore(false)}
                      >
                        Read less
                      </span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="about-highlights">
                <h4>Government Initiatives</h4>

                <p>
                  Recognizing the gravity of the situation, the Indian
                  government is taking measures to promote healthier lifestyles.{" "}
                  <br />
                  1. Promoting YOGA <br />
                  2. Promoting Healthy Lifestyle <br />
                  3. Promoting Sports <br />
                  Our Honourable PM Shri Narendra Modi is greatest IDLE of
                  FITNESS for ALL our INDIAN YOUTH At this age also he is having
                  energy and efficiency more than a youth. As a part of this
                  campaign and our Honourable PM Shri narendra Modi's vision
                  lets make India New super power and Proeperous by making our
                  youth Fit and Healthy.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 align-items-stretch order-1 order-lg-2 img why-us-bg"
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  );
}
