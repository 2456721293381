import React, { useState } from "react";
import side1 from "../assets/img/side1.svg";
import ab1 from "../assets/img/icons/about/ab1.svg";
import ab2 from "../assets/img/icons/about/ab2.svg";
import ab3 from "../assets/img/icons/about/ab3.svg";
import ab4 from "../assets/img/icons/about/ab4.svg";
import ab5 from "../assets/img/icons/about/ab5.svg";
import ab6 from "../assets/img/icons/about/ab6.svg";

export default function Main() {
  const [readMore, setReadMore] = useState(false);
  return (
    <div>
      {" "}
      <main id="main">
        <section id="skills" className="skills">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <img src={side1} className="img-fluid animated" alt="" />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 content"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <div className="d-flex">
                  <div className="side-1-line"></div>
                  <div className="about-nobesity">
                    <p>About Nobesity</p>
                    <h3>
                      <span className="org-text">
                        {" "}
                        Obesity is a new Pandemic{" "}
                      </span>
                      quietly sweeping INDIA
                    </h3>
                  </div>
                </div>

                <div className="about-details">
                  <p>
                    In the bustling landscape of India, a silent pandemic is
                    making its presence felt - Obesity. It's a pressing issue
                    that demands our attention. Did you know In INDIA 1 in 4
                    adult is overweight or obese and if this continues with the
                    same rate INDIA obesity rate is set to increase by more than
                    80% by 2035. And Obesity Leads to many lifestyle diseases
                    like cardiovascular disease, diabetes, thyroid.{" "}
                    {readMore ? (
                      ""
                    ) : (
                      <span
                        className="read-more"
                        onClick={() => setReadMore(true)}
                      >
                        Read more
                      </span>
                    )}
                  </p>
                  {readMore ? (
                    <div>
                      <p>
                        For these diseases, economic costs are enormous.
                        Healthcare costs and productivity/efficiency loss
                        resulting from the overweight population are projected
                        to be at the top around $129 billion by 2035, i.e., 2%
                        of INDIA'S GDP. It is the responsibility of all INDIANS
                        to start the battle against this silent enemy of the
                        country's prosperity. As the first step of this battle,
                        a new movement/campaign - NOBESITY - INDIA FIGHTS WITH
                        OBESITY has been initiated.{" "}
                        <span
                          className="read-less"
                          onClick={() => setReadMore(false)}
                        >
                          Read less
                        </span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="about-highlights">
                  <h4>
                    For Preparation of the battle against Obesity, avoid this
                  </h4>
                </div>
                <div>
                  <span className="badge info-about no-margin">
                    <img src={ab1} alt="" />
                    Sedentary Lifestyle and Work
                  </span>
                  <span className="badge info-about no-margin">
                    <img src={ab2} alt="" />
                    Mental Stress
                  </span>
                </div>
                <div>
                  <span className="badge info-about no-margin">
                    <img src={ab3} alt="" />
                    High Caloric Diets
                  </span>
                  <span className="badge info-about no-margin">
                    <img src={ab4} alt="" />
                    Urbanization and Fast Food
                  </span>
                </div>
                <div>
                  <span className="badge info-about no-margin">
                    <img src={ab5} alt="" />
                    Cultural Dietary Shifts
                  </span>
                  <span className="badge info-about no-margin">
                    <img src={ab6} alt="" />
                    Lack of Awareness
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
