import React, { useRef, useState } from "react";
import upload from "../assets/img/upload.svg";
import uploadV from "../assets/img/uploadVideo.svg";
import { Link } from "react-router-dom";
import { Button, Modal } from "bootstrap";
import { SuccessModal } from "./SuccessModal";

export default function UploadVideo() {
  const [videoPreview, setVideoPreview] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleImageClick = () => {
    // Trigger the click on the hidden file input
    document.getElementById("fileInput").click();
  };

  //   const handleFileChange = (event) => {
  //     const selectedFile = event.target.files[0];

  //     // Check the file type
  //     if (selectedFile) {
  //       const fileType = selectedFile.type;
  //       if (fileType === "video/mp4" || fileType === "video/x-matroska") {
  //         // Create a video preview URL
  //         const previewURL = URL.createObjectURL(selectedFile);

  //         // Set the video preview URL
  //         setVideoPreview(previewURL);
  //       } else {
  //         // Display an error message or prevent further processing
  //         console.error("Invalid file type. Please select an MP4 or MKV file.");
  //       }
  //     }
  //   };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    // Check the file type
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "video/mp4" || fileType === "video/x-matroska") {
        try {
          // Make a GET request to the API endpoint to obtain the pre-signed URL
          const response = await fetch(
            "https://nv5k51rvoc.execute-api.ap-south-1.amazonaws.com/prod/generateUploadURL"
          );

          console.log("response", response);

          const data = await response.json();

          console.log("data", data);

          // Extract the pre-signed URL from the response
          const preSignedURL = data.presignedUrl;

          console.log("preSignedURL", preSignedURL);

          // Create a video preview URL
          const previewURL = URL.createObjectURL(selectedFile);

          // Set the video preview URL
          setVideoPreview(previewURL);

          // Make a PUT request to upload the file to the pre-signed URL
          await fetch(preSignedURL, {
            method: "PUT",
            body: selectedFile,
          });

          // Now you can use the preSignedURL as a CDN for the uploaded file
          console.log("File uploaded successfully:", preSignedURL);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      } else {
        console.error("Invalid file type. Please select an MP4 or MKV file.");
      }
    }
  };

  const handleSuccessButtonClick = () => {
    // Trigger the success modal
    setTimeout(() => {
      setVideoPreview(false);
    }, 3000);
  };

  return (
    <>
      {/* <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button> */}

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Video Uploaded Successfully
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="dashbord-bg">
        <section class="thanku_section">
          <div class={videoPreview ? "thnaku-box-upload" : "thnaku-box"}>
            {videoPreview ? (
              <>
                <div className="col-lg-9 d-flex justify-content-center">
                  <video controls width="100%" height="400">
                    <source src={videoPreview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div
                  className="col-lg-3 text-center"
                  style={{ paddingLeft: "30px" }}
                >
                  <div>Title</div>
                  <hr />

                  <div>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Description"
                    ></textarea>
                  </div>
                  <div className="mt-2">
                    <button
                      onClick={handleSuccessButtonClick}
                      className="btn-get-started justify-content-center"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{
                        borderRadius: "0px",
                        border: "none",
                        background: "#109F8E",
                        width: "100%",
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="col-lg-6 d-flex justify-content-center">
                  <Link to="/record-video">
                    <img src={upload} alt="" className="img-fluid" />
                  </Link>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <img
                    src={uploadV}
                    alt=""
                    srcSet=""
                    className="img-fluid"
                    style={{ cursor: "pointer" }}
                    onClick={handleImageClick}
                  />
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    accept=".mp4, .mkv"
                    onChange={handleFileChange}
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
