import { message } from "antd";
import React, { useState } from "react";

export default function DeleteUser() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const deleteUser = async (e) => {
    e.preventDefault();
    try {
      const apiUrl =
        "https://z4cnkjtulk.execute-api.ap-south-1.amazonaws.com/prod/deleteUser";

      const response = await fetch(apiUrl, {
        method: "POST", // or 'POST' depending on your API
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // pass email in the request body
      });

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      messageApi.open({
        type: "success",
        content: "Email deleted successfully",
      });

      // Process the successful response here
      console.log("User deleted successfully");
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Email does not exist",
      });
      console.log(error);
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);

    if (!isValid) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  return (
    <>
    {contextHolder}
    
    <div class="dashbord-bg">
      <section class="thanku_section login-contact">
        <div class={"thnaku-box"}>
          <div className="col-lg-12">
            <div className="text-center">
              <form id="registrationForm3" role="form" onSubmit={deleteUser}>
                <div className="row">
                  <div className="form-group col-md-12 pb-3">
                    <div className="field">
                      <input
                        type="text"
                        required
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          validateEmail(e.target.value);
                        }}
                      />

                      <label>Email Id</label>
                    </div>
                    {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                  </div>
                </div>
                <div className="text-center d-flex mt-3 justify-content-center">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>

  );
}
