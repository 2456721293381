import React from "react";
import bl1 from "../assets/img/icons/blogs/bl1.svg";
import bl2 from "../assets/img/icons/blogs/bl2.svg";
import bl3 from "../assets/img/icons/blogs/bl3.svg";
import rm from "../assets/img/icons/blogs/readmore.svg";

export default function Blog() {
  return (
    <section id="blogs" className="blogs">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col-lg-12 d-flex align-items-center justify-content-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="d-flex">
              <div className="about-nobesity blog-des text-center d-flex justify-content-center align-items-center">
                <p>Latest Blogs</p>
                <h3>
                  Research and {" "}
                  <span className="org-text">Articles of Obesity</span>
                </h3>
                <div className="blog-line"></div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 d-flex align-items-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="blog-box">
              <img src={bl1} className="img-fluid blog-pic" alt="" />
              <div className="about-highlights blog-details">
                <h4>Obesity and overweight</h4>
                <p>
                  Overweight and obesity are defined as abnormal or excessive
                  fat accumulation that may impair health.
                </p>
                <div>
                  <a
                    href="https://www.who.int/news-room/fact-sheets/detail/obesity-and-overweight"
                    className="btn-get-started scrollto"
                    target="_blank"
                  >
                    Read more
                    <img
                      src={rm}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 d-flex align-items-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="blog-box">
              <img
                src={bl2}
                className="img-fluid blog-pic"
                alt=""
              />
              <div className="about-highlights blog-details">
                <h4>Health Policy and Planning</h4>
                <p>
                  Overweight and obesity in India: policy issues from an
                  exploratory multi-level analysis
                  <br />
                  &nbsp;
                </p>
                <div>
                  <a
                    href="https://academic.oup.com/heapol/article/31/5/582/2355548
                    "
                    className="btn-get-started scrollto"
                    target="_blank"
                  >
                    Read more
                    <img
                      src={rm}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 d-flex align-items-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="blog-box">
              <img
                src={bl3}
                className="img-fluid blog-pic"
                alt=""
              />
              <div className="about-highlights blog-details">
                <h4>Obesity Rises in Rural India</h4>
                <p>
                  We show that the rise in rural obesity in India is closely
                  associated with urbanization of its rural spaces.
                </p>
                <div>
                  <a
                    href="https://www.sciencedirect.com/science/article/pii/S0305750X20303855"
                    className="btn-get-started scrollto"
                    target="_blank"
                  >
                    Read more
                    <img
                      src={rm}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
