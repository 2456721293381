import React from "react";
import Navbar from "./Navbar";
import Term from "./Term";
import Footer from "./Footer";

export default function Privacy() {
  return (
    <>
      <Navbar />
      <section id="hero" className="d-flex align-items-center about-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-center">
                <h1 className="title-detail">Privacy Policy</h1>
              </div>

              <p className="title-para text-center"></p>
              <div className="text-center">
                <a href="/" className="details-page">
                  <span className="home"> Home / </span>
                  <span className="detail"> Privacy Policy </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="about-highlights">
                <h4>Privacy Policy</h4>

                <p>
                  This Privacy Policy describes how Nobesity-India fights with
                  obesity collects, uses, and protects your personal information
                  when you visit and use the website and app of
                  www.nobesityindia.com. By accessing and using the Site/app,
                  you agree to the terms of this Privacy Policy. If you do not
                  agree to these terms, please do not use the Site.
                </p>

                <p>
                  "Nobesity-India fights with obesity," is committed to
                  safeguarding the privacy and security of personal information
                  collected from individuals participating in our health
                  awareness program. This Privacy Policy outlines how we
                  collect, use, disclose, and protect your information in
                  connection with the Nobesity-India fights with obesity
                  program.
                </p>

                <p>
                  1. Information Collection: We may collect personal information
                  directly from individuals participating in the Nobesity-India
                  fights with obesity program. The information we collect may
                  include, but is not limited to:
                </p>
                <ul>
                  <li>Name</li>
                  <li>Contact details (email address, phone number)</li>
                  <li>Age and date of birth</li>
                  <li>Gender</li>
                  <li>Health-related information (BMI, fitness goals)</li>
                </ul>

                <p>
                  2. Purpose of Information Collection: <br />
                  The information collected is used for the following purposes:
                </p>

                <ul>
                  <li>
                    To enroll individuals in the Nobesity-India fights with
                    obesity program
                  </li>
                  <li>
                    To personalize and tailor health awareness content and
                    recommendations
                  </li>
                  <li>
                    To communicate program updates, rewards, and educational
                    materials
                  </li>
                  <li>
                    To analyze program effectiveness and participant engagement
                  </li>
                </ul>

                <p>
                  3. Information Usage: Nobesity-India fights with obesity will
                  use the collected information solely for the purposes stated
                  in this Privacy Policy. We will not sell, rent, or lease your
                  personal information to third parties. Your information may be
                  shared with our service providers only to the extent necessary
                  for them to perform services on our behalf.
                </p>
                <p>
                  4. Security Measures: We implement reasonable and appropriate
                  security measures to protect against unauthorized access,
                  alteration, disclosure, or destruction of personal
                  information. Despite our efforts, no data transmission over
                  the internet or any wireless network can be guaranteed to be
                  100% secure.
                </p>
                <p>
                  5. Cookies and Tracking Technologies: We may use cookies and
                  similar tracking technologies to enhance user experience and
                  gather information about how users interact with our online
                  platforms. Users can control the use of cookies through their
                  browser settings.
                </p>
                <p>
                  6. Third-Party Links: The Nobesity-India fights with obesity
                  program may contain links to third-party websites or services.
                  Nobesity-India fights with obesity is not responsible for the
                  privacy practices or content of such third-party sites. We
                  encourage users to review the privacy policies of these
                  third-party services.
                </p>
                <p>
                  7. Changes to the Privacy Policy: Nobesity-India fights with
                  obesity reserves the right to update or modify this Privacy
                  Policy at any time without prior notice. The effective date of
                  the policy will be revised accordingly. Users are encouraged
                  to review this Privacy Policy periodically for any changes.
                </p>

                <p>
                  8. Contact Information: If you have any questions or concerns
                  about this Privacy Policy or the treatment of your personal
                  information, please contact us at: Email:
                  Support@Nobesity-India fights with obesity.com Thank you for
                  participating in the Nobesity-India fights with obesity
                  program. Your privacy and trust are important to us.
                </p>

                {/* <h4 style={{ marginTop: "40px" }}>Refund Policy</h4>
                <p>
                  This Refund Policy applies to the health awareness program
                  offered by Stallon Corporation under the brand name
                  "NobisityIndia." By participating in the program, you agree to
                  adhere to the terms outlined in this Refund Policy.
                </p>

                <p>
                  1. Enrollment Fee: <br />
                  The NobisityIndia program may have an enrollment fee or
                  associated costs for certain features. Payment details will be
                  clearly communicated during the enrollment process.
                </p>

                <p>
                  2. Refund Eligibility: <br /> Refunds will be considered under
                  the following circumstances:
                  <ul>
                    <li>
                      Technical Errors: In the event of a technical error
                      resulting in duplicate charges or other payment
                      discrepancies, Stallon Corporation will refund the excess
                      amount promptly upon verification.
                    </li>
                    <li>
                      Program Cancellation: If Stallon Corporation cancels the
                      NobisityIndia program for any reason, participants will be
                      entitled to a refund of any prepaid fees for the remainder
                      of the program.
                    </li>
                  </ul>
                </p>

                <p>
                  3. Non-Refundable Items: <br />
                  Certain items or services within the NobisityIndia program may
                  be explicitly designated as non-refundable. This includes, but
                  is not limited to, personalized health assessments,
                  downloadable content, and virtual rewards.
                </p>

                <p>
                  4. Refund Request Procedure: <br />
                  If you believe you are eligible for a refund, please follow
                  the procedure below:
                  <ul>
                    <li>
                      Contact our customer support team at
                      Support@Nobisityindia.com Provide detailed information
                      regarding the reason for your refund request.
                    </li>
                    <li>
                      Stallon Corporation will review the request and, if
                      eligible, process the refund within a reasonable
                      timeframe.
                    </li>
                  </ul>
                </p>

                <p>
                  5. Refund Method: <br />
                  Refunds, when approved, will be processed using the original
                  payment method. Please note that processing times may vary
                  based on the payment provider.
                </p>

                <p>
                  6. Modification of Fees: <br />
                  Stallon Corporation reserves the right to modify enrollment
                  fees or introduce new fees for additional features or services
                  within the NobisityIndia program. Any changes to fees will be
                  communicated to participants in advance.
                </p>
                <p>
                  7. Dispute Resolution: <br />
                  If you are dissatisfied with the resolution of your refund
                  request, you may follow the dispute resolution procedures
                  outlined in the Terms and Conditions.
                </p>

                <p>
                  By participating in the NobisityIndia program, you acknowledge
                  that you have read, understood, and agree to this Refund
                  Policy. Stallon Corporation reserves the right to update or
                  modify this Refund Policy at any time without prior notice.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
