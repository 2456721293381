import React, { useState } from "react";
import JoinMovement from "./JoinMovement";
import arrow from "../assets/img/icons/arrow.svg";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Contact() {
  const [sucess, setSuccess] = useState(false);

  return (
    <div>
      <Navbar />
      <section id="hero" className="d-flex align-items-center about-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-center">
                <h1 className="title-detail">Contact us</h1>
              </div>

              <p className="title-para text-center"></p>
              <div className="text-center">
                <a href="/" className="details-page">
                  <span className="home"> Home / </span>
                  <span className="detail"> Contact us </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="contact mb-5">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
              <form method="post" role="form" className="php-email-form">
                {sucess ? (
                  "  Your message has been sent. Team will get back to you shortly."
                ) : (
                  <>
                    {" "}
                    <div className="row">
                      <div className="about-highlights no-top">
                        <h4>Let’s us know</h4>

                        <p>We would love to hear from you.</p>
                      </div>

                      <div className="form-group col-md-12">
                        <div className="field">
                          <input type="text" required />
                          <label>Full name*</label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="field">
                          <input type="text" required />
                          <label>Mobile Number*</label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="field">
                          <input type="text" required />
                          <label>Email Address*</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="field">
                        <input type="text" required />
                        <label>Subject</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="field text-area">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="5"
                          required
                        ></textarea>
                        <label>Subject</label>
                      </div>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    </div>
                    <div className="text-center ">
                      <button
                        type="submit"
                        className="d-flex align-items-center justify-content-center"
                        onClick={() => setSuccess(true)}
                      >
                        Submit <img src={arrow} alt="" className="img-fluid" />
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>

      <JoinMovement />

      <Footer />
    </div>
  );
}
