import React from "react";
import Navbar from "./Navbar";
import Term from "./Term";
import Footer from "./Footer";

export default function Refund() {
  return (
    <>
      <Navbar />
      <section id="hero" className="d-flex align-items-center about-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-center">
                <h1 className="title-detail">Refund Policy</h1>
              </div>

              <p className="title-para text-center"></p>
              <div className="text-center">
                <a href="/" className="details-page">
                  <span className="home"> Home / </span>
                  <span className="detail"> Refund Policy </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="about-highlights">
                <h4>Refund Policy</h4>
                <p>
                  This Refund Policy applies to the health awareness program
                  offered by Stallion Corporation under the brand name
                  "NobesityIndia." By participating in the program, you agree to
                  adhere to the terms outlined in this Refund Policy.
                </p>

                <p>
                  1. Enrollment Fee:
                  <br />
                  The NobesityIndia program may have an enrollment fee or
                  associated costs for certain features. Payment details will be
                  clearly communicated during the enrollment process.
                </p>

                <p>
                  2. Refund Eligibility: <br /> Refunds will be considered under
                  the following circumstances:
                  <ul>
                    <li>
                      Technical Errors: In the event of a technical error
                      resulting in duplicate charges or other payment
                      discrepancies, Stallion Corporation will refund the excess
                      amount promptly upon verification.
                    </li>
                    <li>
                      Program Cancellation: If Stallion Corporation cancels the
                      NobesityIndia program for any reason, participants will be
                      entitled to a refund of any prepaid fees for the remainder
                      of the program.
                    </li>
                  </ul>
                </p>

                <p>
                  3. Non-Refundable Items: <br />
                  Certain items or services within the NobesityIndia program may
                  be explicitly designated as non-refundable. This includes, but
                  is not limited to, personalized health assessments,
                  downloadable content, and virtual rewards.
                </p>

                <p>
                  4. Refund Request Procedure: <br />
                  If you believe you are eligible for a refund, please follow
                  the procedure below:
                  <ul>
                    <li>
                      Contact our customer support team at Support@Nobes.com
                      Provide detailed information regarding the reason for your
                      refund request.
                    </li>
                    <li>
                      Stallon Corporation will review the request and, if
                      eligible, process the refund within a reasonable
                      timeframe.
                    </li>
                  </ul>
                </p>

                <p>
                  5. Refund Method: <br />
                  Refunds, when approved, will be processed using the original
                  payment method. Please note that processing times may vary
                  based on the payment provider.
                </p>

                <p>
                  6. Modification of Fees: <br />
                  Stallion Corporation reserves the right to modify enrollment
                  fees or introduce new fees for additional features or services
                  within the NobesityIndia program. Any changes to fees will be
                  communicated to participants in advance.
                </p>
                <p>
                  7. Dispute Resolution: <br />
                  If you are dissatisfied with the resolution of your refund
                  request, you may follow the dispute resolution procedures
                  outlined in the Terms and Conditions.
                </p>

                <p>
                  8. Contact Information: <br />
                  For any questions or concerns related to refunds, please
                  contact our customer support team at:
                  <br />
                  Email: Support@Nobisityindia.com
                </p>

                <p>
                  By participating in the NobisityIndia program, you acknowledge
                  that you have read, understood, and agree to this Refund
                  Policy. Stallon Corporation reserves the right to update or
                  modify this Refund Policy at any time without prior notice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
