import React from "react";
import logo from "../assets/img/logo.svg";
import { useEffect } from "react";


export default function Navbar() {


   useEffect(() => {

    // console.log("Navbar Render");
    // // Mobile nav toggle
    // const handleMobileNavToggle = (e) => {
    //   const navbar = document.getElementById('navbar');
    //   navbar.classList.toggle('navbar-mobile');
    //   e.target.classList.toggle('bi-list');
    //   e.target.classList.toggle('bi-x');
    // };

    // // Mobile nav dropdowns activate
    // const handleMobileNavDropdown = (e) => {
    //   const navbar = document.getElementById('navbar');
    //   const dropdown = e.target.nextElementSibling;

    //   if (navbar.classList.contains('navbar-mobile')) {
    //     e.preventDefault();
    //     dropdown.classList.toggle('dropdown-active');
    //   }
    // };

    // // Attach event listeners
    // document.addEventListener('click', (e) => {
    //   if (e.target.classList.contains('mobile-nav-toggle')) {
    //     handleMobileNavToggle(e);
    //   }

    //   if (e.target.classList.contains('navbar') && e.target.closest('.dropdown > a')) {
    //     handleMobileNavDropdown(e);
    //   }
    // });

    // // Cleanup function
    // return () => {
    //   document.removeEventListener('click', handleMobileNavToggle);
    //   document.removeEventListener('click', handleMobileNavDropdown);
    // };
  },[]); // Empty dependency array ensures that the effect runs once after the initial render

  const handleMobileNavToggle = (e) => {
    const navbar = document.getElementById("navbar");

    if (navbar) {
      navbar.classList.toggle("navbar-mobile");
    }

    e.target.classList.toggle("bi-list");
    e.target.classList.toggle("bi-x");
  };

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center no-header">
        <h1 className="logo me-auto">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </h1>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              
              <a className="nav-link scrollto active" href="/">
                Home
              </a>
              <div className="underline-nav"></div>
            </li>

            <li>
              <a className="nav-link scrollto" href="/events">
                Events
              </a>
              <div className="underline-nav"></div>
            </li>
            <li>
              <a className="nav-link scrollto" href="/about">
                About Us
              </a>
              <div className="underline-nav"></div>
            </li>
           
            {/* <li>
              <a className="nav-link scrollto" href="#blog">
                Blogs
              </a>
              <div className="underline-nav"></div>
            </li> */}
            <li>
              <a className="nav-link scrollto" href="/contact">
                Contact
              </a>
              <div className="underline-nav"></div>
            </li>

            <li>
              <a className="volunteer scrollto" href="https://coach.nobesityindia.com/" target="_blank">
              Health Coach
              </a>
            </li>
            <li>
              <a className="getstarted scrollto" href="/login">
             Join Challenge
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle}></i>
        </nav>
      </div>
    </header>
  );
}
