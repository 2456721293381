import React from "react";
import reward from "../assets/img/rewardside.svg";
import gs from "../assets/img/icons/getstart.svg";

export default function Reward() {
  return (
    <section id="skills" className="skills">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <img src={reward} className="img-fluid animated" alt="" />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 content"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="d-flex">
              <div className="side-1-line reward-line sixty-days"></div>
              <div className="about-nobesity">
                <p>Health Revolution</p>
                <h3>
                  Motivation and {" "}
                  <span className="org-text">Rewards</span>
                </h3>
              </div>
            </div>

            <div className="about-details">
              <p>
                Join the competition within each category to bring your {" "}
                <span className="bold-about" style={{ paddingRight: "0px" }}>
                  BMI closer to 25.
                </span>{" "}
                The top 5 participants in each category will be  {" "}
                <span className="bold-about" style={{ paddingRight: "0px" }}>
                  awarded ₹3 lakhs
                </span> {" "}
                each. Coaches guiding others to success will receive special
                recognition. The first 200 registrations under the "Coach of
                Health Team India" category will be awarded health insurance
                with a sum insured of {" "}
                <span className="bold-about" style={{ paddingRight: "0px" }}>
                  ₹3 lakhs for one year.
                </span>
              </p>
            </div>

            <div className="about-highlights">
              <h4>Share Your Journey</h4>
              <p>
                Participate with enthusiasm!  {" "}
                <span className="bold-about" style={{ paddingRight: "0px" }}>
                  Upload your daily workout videos and pictures {" "}
                </span>
                on the  {" "}
                <span className="bold-about" style={{ paddingRight: "0px" }}>
                  Nobesity app
                </span>  {" "}
                to motivate others and maximize participation
              </p>
            </div>
            <div className="about-highlights">
              <h4>Let's Fight Obesity, Together!</h4>
              <p>
                Our ultimate goal is a fit youth, a fit India, and a prosperous
                India. Join the Nobesity movement with full vigor and contribute
                to making India healthier and stronger.
              </p>

              <a href="/login" className="btn-get-started scrollto">
                Get Started
                <img src={gs} alt=""  className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
