import React from "react";
import check from "../assets/img/icons/rightcheck.svg";
import volunteer from "../assets/img/volunteer.svg";
import volunteer2 from "../assets/img/volunteerbn.svg";
import star from "../assets/img/icons/star.svg";
import gift from "../assets/img/icons/gift.svg";
import Footer from "./Footer";

export default function Volunteer() {
  return (
    <>
      <section id="hero" className="d-flex align-items-center about-page">
        <div className="container about-container">
          <div className="row">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-center">
                <h1 className="title-detail">
                  Apply Now to Volunteer / Internships <br />
                  and Be the Change!
                </h1>
              </div>

              <p className="title-para text-center"></p>
              <div className="text-center vol-div">
                <a
                  href="https://certifyrecruit.com/nobesityemployee/register"
                  target="_blank"
                  className="btn-get-started scrollto"
                >
                  <img
                    src={check}
                    alt=""
                    className="img-fluid"
                    style={{
                      paddingRight: "5px",
                      paddingLeft: "0px",
                      marginLeft: "0px",
                    }}
                  />
                  Apply now
                </a>
                <a href="/" className="volunteer scrollto vol-btn">
                  Nobesity home page
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg vol-det">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="content">
                <div className="d-flex">
                  <div className="side-1-line c-line sixty-days"></div>
                  <div className="about-nobesity roles">
                    <h3>Roles and Responsibilities</h3>
                  </div>
                </div>
              </div>

              <div className="accordion-list vol-det">
                <div className="about-details">
                  <ul>
                    <li>
                      <p>
                        Obesity is a slowly advancing pandemic in India,
                        becoming a root cause of various lifestyle diseases
                        among our youth, thereby reducing their efficiency. Our
                        goal as volunteers is to comprehend how obesity is
                        affecting and impeding the realization of our honorable
                        Prime Minister's vision of making India a prosperous
                        country.
                      </p>
                    </li>
                    <li>
                      <p>
                        To achieve this, we will raise awareness among our
                        immediate surroundings—friends, family, colleagues,
                        social media connections, and everyone within our
                        reach—educating them on how to stay fit and contribute
                        to India's prosperity through the NObesity initiative,
                        the fight against obesity.
                      </p>
                    </li>
                    <li>
                      <p>
                        For a successful campaign, it's crucial to bring people
                        onto a common platform. To effectively spread awareness
                        about NObesity and India's fight against obesity, we
                        will encourage everyone to join the NObesity platform
                        (website & app) and become a part of this significant
                        health event.
                      </p>
                    </li>

                    <li>
                      <p>
                        To kickstart this revolutionary movement, aimed at
                        making the entire country fit, we are launching a
                        competition. All Indian youth are invited to participate
                        on the app by entering their health details, following
                        exercise and diet recommendations from their
                        doctors/dieticians/experts, updating their progress
                        daily, and striving to be among the top performers in
                        their respective groups. The top 20 candidates in each
                        group stand a chance to win a cash prize of 3 lakh
                        rupees each.
                      </p>
                    </li>
                    <li>
                      <p>
                        Candidates will compete within their own groups to
                        secure a spot in the top 5. Those with a BMI of 25 will
                        be categorized as Coaches of Nobesity; while they won't
                        be eligible for the cash prize, the first 200 registered
                        coaches will receive free health insurance coverage of 3
                        lakhs for one year. This initiative aims to make a
                        significant impact on the health and well-being of the
                        youth of India.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 align-items-stretch order-1 order-lg-2 img"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "40px" }}
              >
                <img src={volunteer} alt="" className="img-fluid" />
              </div>

              <div className="about-highlights card-font volunteer-list">
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  Individuals with BMI 25: Coach of Health Team India
                </div>
                <br />
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  26-30: Captain of Health Team India
                </div>
                <br />
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  31-35: Vice Captain of Health Team India
                </div>
                <br />
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  36-40: Best All-Rounder of Health Team India
                </div>
                <br />
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  41-45: Best Player of Health Team India
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="skills" className="skills">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 pt-4 pt-lg-0 content"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="d-flex">
                <div className="side-1-line c-line sixty-days"></div>
                <div className="about-nobesity">
                  <h3>Rewards/Benifits</h3>
                </div>
              </div>

              <div className="about-highlights card-font vol-img">
                <div className="info-about no-margin">
                  <img src={gift} alt="" className="img-fluid" />
                  You will only be eligible to receive a stipend when you score
                  1200+ points.
                </div>
                <div className="info-about no-margin">
                  <img src={gift} alt="" className="img-fluid" />
                  To be eligible for the leaderboard, you must score above 1200
                  points. The top 20 individuals on the leaderboard will receive
                  a 50K incentive.
                </div>
                <div className="info-about no-margin">
                  <img src={gift} alt="" className="img-fluid" />
                  All interns/volunteers will receive a participation
                  certification.
                </div>
                <div className="info-about no-margin">
                  <img src={gift} alt="" className="img-fluid" />
                  For the top 50 individuals, we will assist you in finding the
                  next opportunity after completing a one-round interview with
                  CertifyRecruit.
                </div>
                <div className="info-about no-margin">
                  <img src={gift} alt="" className="img-fluid" />
                  The top 100 individuals on the leaderboard will receive a
                  Nobisity India T-shirt and notepad.
                </div>
              </div>
            </div>

            <div
              className="col-lg-1 pt-4 pt-lg-0 content"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              &nbsp;
            </div>

            <div
              className="col-lg-5 d-flex align-items-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={volunteer2} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg vol-det">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="content">
                <div className="d-flex">
                  <div className="side-1-line c-line sixty-days"></div>
                  <div className="about-nobesity">
                    <h3>Rules and Regulation</h3>
                  </div>
                </div>
              </div>

              <div className="accordion-list">
                <div className="about-details">
                  <ul>
                    <li>
                      <p>
                        Each intern is tasked with the minimum responsibility of
                        scoring 1200 points within 60 days. Registering each
                        candidate at NoBesity earns you 10 points. It's
                        essential to emphasize that this effort is dedicated to
                        a great cause, requiring a high level of dedication and
                        discipline. We are building the Fit Youth Army of India,
                        and this army is expected to contribute significantly to
                        the prosperity of the nation if its members are fit.
                      </p>
                    </li>
                    <li>
                      <p>
                        Code of Conduct: Interns must strictly adhere to ethical
                        standards. Any violation or suspicious activity will
                        result in immediate removal from the project.
                      </p>
                    </li>
                    <li>
                      <p>
                        Performance Metrics: Failure to achieve the required
                        number of registrations within 60 days may lead to
                        removal without a stipend.
                      </p>
                    </li>
                    <li>
                      <p>
                        All interns will receive a certification of their
                        participation.
                      </p>
                    </li>
                    <li>
                      <p>
                        As candidates cross the 1200-point milestone, they will
                        move to the next level—Leadership Board.
                        Interns/volunteers on the Leadership Board will receive
                        special benefits:
                      </p>
                    </li>

                    <li>
                      <p className="bold-about">
                        The first 10 will be awarded a prize money of 50,000/-
                      </p>
                    </li>
                    <li>
                      <p className="bold-about nb-margin">
                        For the first 50 individuals, Certify Recruit will
                        assist in finding a good job after completing a
                        one-round interview with Certify.
                      </p>
                    </li>
                    <li>
                      <p className="bold-about nb-margin">
                        The first 100 will receive FREE merchandise from
                        NoBesity.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="accordion-list">
                <div className="about-details">
                
                  <ul>
                    <li>
                      <p style={{ marginTop: "-10px" }}>
                        This initiative aims to create a Fit youth health INDIA
                        team for India, and your commitment and hard work will
                        contribute to the success of this noble cause.
                      </p>
                    </li>
                    <li>
                      <p>
                        Fraud Prevention: Any intern or individual engaged in
                        fraud, offline money collection, or illegal activities
                        will face strict legal actions.
                      </p>
                    </li>
                    <li>
                      <p>
                        Intern's Will be paid after 60 Days only, if he/she is
                        able to earn 1200 Points, Within 60 Days
                      </p>
                    </li>
                    <li>
                      <p>
                        Interns will be paid 2 months of stipend together with
                        their incentive if they are able to achieve the target.
                      </p>
                    </li>
                    <li>
                      <p>Every registration you make will earn you 10 points</p>
                    </li>
                    <li>
                      <p> And at the end of 60 days,</p>
                    
                    </li>
                    
                    <li>
                    <p className="bold-about nb-margin">
                        20 Interns with highest points earned amongst leadership
                        board will be awarded with prize money of 50k
                      </p>
                    </li>
                    <li>
                      <p className="bold-about nb-margin">
                        First 50 interns will be offered Full time job (only
                        after 1 round interview with Certify recruit team).
                      </p>
                    </li>

                    <li>
                      <p className="bold-about nb-margin">
                        First 100 interns will be given T shirts of Nobesity
                        India.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
