import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { useReactMediaRecorder } from "react-media-recorder";

export default function RecordVideo() {
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ video: true });

  const webcamRef = useRef(null);
  const [retakeMode, setRetakeMode] = useState(false);

  const handleStartRecording = () => {
    startRecording();
    setRetakeMode(false);
  };

  const handleStopRecording = () => {
    stopRecording();
    setRetakeMode(true);
  };

  const handleRetake = () => {
    setRetakeMode(false);
  };

  const handleUpload = async () => {
    console.log("Click");
    if (mediaBlobUrl) {
      try {
        // Make a GET request to the API endpoint to obtain the pre-signed URL
        const response = await fetch(
          "https://nv5k51rvoc.execute-api.ap-south-1.amazonaws.com/prod/generateUploadURL"
        );

        const data = await response.json();

        // Extract the pre-signed URL from the response
        const preSignedURL = data.presignedUrl;

        // Make a PUT request to upload the file to the pre-signed URL
        await fetch(preSignedURL, {
          method: "PUT",
          body: await fetch(mediaBlobUrl).then((r) => r.blob()),
        });

        // Now you can use the preSignedURL as a CDN for the uploaded file
        console.log("File uploaded successfully:", preSignedURL);
        startRecording();
        setRetakeMode(false);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No recorded video to upload.");
    }
  };

  return (
    <div className="dashbord-bg">
      <section className="thanku_section">
        <div className="thnaku-box">
          <div className="col-lg-12">
            <div className="text-center">
              <p>Video Status: {status}</p>
              <div className="mb-2">
                {status === "recording" && (
                  <Webcam
                    audio={false}
                    mirrored={true}
                    ref={webcamRef}
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
              </div>
              <button
                onClick={handleStartRecording}
                className="btn-get-started justify-content-center"
                disabled={status === "recording"}
              >
                {status === "stopped" ? "Retake" : "Start Recording"}
              </button>{" "}
              &nbsp;&nbsp;&nbsp;
              {status === "stopped" ? (
                <button
                  onClick={handleUpload}
                  className="btn-get-started justify-content-center"
                  // disabled={status !== "recording"}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Upload
                </button>
              ) : (
                <button
                  onClick={handleStopRecording}
                  className="btn-get-started justify-content-center"
                  disabled={status !== "recording"}
                >
                  Stop Recording
                </button>
              )}
              <div className="mt-5">
                {status === "recording" ||
                status === "idle" ||
                status === "acquiring_media" ? (
                  ""
                ) : (
                  <>
                    <video src={mediaBlobUrl} controls autoPlay loop />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Video Uploaded Successfully
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
