import React from "react";
import { Link } from "react-router-dom";
import about from "../assets/img/about.svg";
import reward from "../assets/img/rewardside.svg";
import star from "../assets/img/icons/star.svg";
import gs from "../assets/img/icons/getstart.svg";
import Navbar from "./Navbar";
import Footer from "./Footer";
export default function About() {
  return (
    <>
      <Navbar />
      <section id="hero" className="d-flex align-items-center about-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-center">
                <h1 className="title-detail">About Us</h1>
              </div>

              <p className="title-para text-center"></p>
              <div className="text-center">
                <Link to="/" className="details-page">
                  <span className="home"> Home / </span>
                  <span className="detail"> About us </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="content">
                <div className="d-flex">
                  <div className="side-1-line"></div>
                  <div className="about-nobesity">
                    <p>Health Crisis</p>
                    <h3>
                      Empowering Health,
                      <span className="org-text">
                        Transforming Lives - Nobesity
                      </span>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="accordion-list">
                <div className="about-details">
                  <p>
                    Welcome to Nobesity, where we're on a mission to combat the
                    rising tide of obesity in India. As the silent pandemic
                    quietly Sweeping our nation, Nobesity stands as a beacon of
                    awareness, action, and change.
                  </p>
                  <p>
                    India, facing a critical juncture, grapples with alarming
                    obesity statistics - one in four adults is overweight or
                    obese, and the rate is predicted to surge by over 80% by
                    2035. This isn't just a health crisis; it's an economic
                    challenge with projected costs of $129 billion by 2035,
                    impacting productivity, healthcare, and leading to premature
                    deaths.
                  </p>
                  <p>
                    At Nobesity, we recognize the urgency of the situation.
                    Guided by the principles of our visionary Prime Minister,
                    Shri Narendra Modi, we advocate for healthier lifestyles,
                    promoting Yoga, endorsing active living through sports, and
                    inspiring the Indian youth to prioritize fitness.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
              style={{ backgroundImage: `url(${about})` }}
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              &nbsp;
            </div>
          </div>
        </div>
      </section>

      <section id="skills" className="skills">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={reward} className="img-fluid animated" alt="" />
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 content"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="d-flex">
                <div className="side-1-line side-1-line-10 sixty-days"></div>
                <div className="about-nobesity">
                  <p>Health Revolution</p>
                  <h3>
                    Nobesity:
                    <span className="org-text"> The 60 Days Challenge </span>
                  </h3>
                </div>
              </div>

              <div className="about-highlights card-font">
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  Challenge begins from 15th february 2024
                </div>
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  Registration Opens from 14th January 2024
                </div>
                <div className="info-about no-margin">
                  <img src={star} alt="" className="img-fluid" />
                  Pre registration Open from 10th December 2023
                </div>
                <div className="about-details">
                  <p>
                    So Rush Up and be first to be part of India biggest Health
                    Campaign
                  </p>
                  <a href="/login" className="btn-get-started scrollto">
                    Get Started
                    <img src={gs} alt="" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
