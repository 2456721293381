import React, { useEffect, useState } from "react";
import certificate from "../../assets/img/icons/cerFrame.svg";
import badge from "../../assets/img/icons/cerbadge.svg";
import logo from "../../assets/img/icons/clogo.svg";
import takePledgeImg from "../../assets/img/icons/pledge.svg";

import "./Certificate.less";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
} from 'react-share';

export default function Certificate(props) {
  console.log(props);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const certificateRef = useRef();

  const additionalFunction = () => {
    setShow(true);
  };

  const handlePrint = useReactToPrint({
    content: () => certificateRef.current,
  });

  return (
    <>
      <div
        className={`certificate ${
          props?.showInline === true ? "inline-cer" : ""
        }`}
        ref={certificateRef}
      >
        <div className="cer-psge">
          {props?.selectedFile && (
            <img
              src={URL.createObjectURL(props?.selectedFile)}
              alt="Preview"
              className="cer-avt"
            />
          )}

          <img src={certificate} alt="" style={{ width: "270px" }} />
        </div>
        <div className="d-flex align-items-center justify-content-center c-title">
          <img src={badge} alt="" style={{ width: "60px" }} />
          Certification <img src={badge} alt="" style={{ width: "60px" }} />
        </div>

        <div className="c-para-2">
          This is to certify that{" "}
          <span className="c-name-2">{(props?.name)?.toUpperCase()}</span> has taken Pledge for
          INDIA’s <br /> Biggest Health campaign Pledge{" "}
          <span className="c-name-n-2">
            {" "}
            NOBESITY - INDIA FIGHT WITH OBESITY.
          </span>
        </div>

        <div className="d-flex justify-content-center c-logo">
          <img src={logo} alt="" />
        </div>
      </div>

      <div className="d-flex">
        <div className="text-center d-flex col-md-8">
          <button
            // onClick={handlePrint}
            onClick={() => {
              handlePrint();

              additionalFunction();
            }}
            className="pledge-btn d-flex align-items-center justify-content-center"
            style={{ marginTop: "50px", marginBottom: "-100px", width: "100%" }}
          >
            Download certificate{" "}
            <img
              src={takePledgeImg}
              className="img-fluid"
              style={{ paddingLeft: "5px" }}
              alt=""
            />
          </button>
        </div>

        <a href="/" className="col-md-4">
          <div className="text-center d-flex ">
            <button
              className="pledge-btn-home"
              style={{
                marginTop: "50px",
                marginBottom: "-100px",
                width: "100%",
                color: "#000 !important",
              }}
            >
              Back to home
            </button>
          </div>
        </a>
      </div>
    </>
  );
}
