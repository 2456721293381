import React, { useEffect, useState } from "react";
import banner from "../assets/img/herobanner.svg";
import loginCh from "../assets/img/icons/loginCheck.svg";
import logo from "../assets/img/loginlogo.svg";
import google from "../assets/img/goggle.png";
import ios from "../assets/img/appstore.png";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { DatePicker, message } from "antd";
import csc from "country-state-city";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

export default function Login() {
  const [showthankYou, setShowThankYou] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [showBmiInfo, setShowBmiInfo] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(false);
  const [insuranceList, setInsuranceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userID, setUserID] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [emailError, setEmailError] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [dbState, setDbState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);

  const location = useLocation();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleAddressChange = (e) => {
    // Handle address change and update state
    setAddress(e.target.value);
  };

  const handleDateChange = (selectedDate, dateInString) => {
    // Update the state with the selected date
    setDob(dateInString);
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Update the state with the selected file
    setSelectedFile(file);

    // Update the label text with the file name
    const label = document.querySelector(".file-input__label span");
    if (label) {
      label.textContent = file ? file.name : "Upload latest photo";
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    // Check for at least 8 characters
    const isLengthValid = newPassword.length >= 8;

    // Check for at least one uppercase letter
    const hasUpperCase = /[A-Z]/.test(newPassword);

    // Check for at least one lowercase letter
    const hasLowerCase = /[a-z]/.test(newPassword);

    // Check for at least one digit
    const hasDigit = /\d/.test(newPassword);

    if (!(isLengthValid && hasUpperCase && hasLowerCase && hasDigit)) {
      setPasswordError(
        "Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one digit."
      );
    } else {
      setPasswordError("");
    }

    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;

    if (newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }

    setConfirmPassword(newConfirmPassword);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const cognito = new CognitoIdentityServiceProvider({
      region: "ap-south-1",
    });

    const params = {
      ClientId: "1ggg857po8jtsno9ijqanls4a7",
      Username: email,
      Password: password,

      UserAttributes: [
        {
          Name: "email",
          Value: email,
        },
        {
          Name: "given_name",
          Value: username,
        },
        {
          Name: "family_name",
          Value: username,
        },
      ],
    };

    try {
      const res = await cognito.signUp(params).promise();
      setUserID(res?.UserSub);

      const query = `mutation MyMutation(
        $email: AWSEmail!,
        $fullName: String,
        $id: ID!,
        $mobileNumber: String
      )

        {
        createUserProfile(input: {
         id: $id
          email: $email,
          fullName: $fullName,
          mobileNumber: $mobileNumber
         }) {
          id
        }
      }`;

      const variables = {
        email: email,
        fullName: username,
        id: res?.UserSub,
        mobileNumber: mobileNumber,
      };

      await axios.post(
        "https://5kz3niiwirg2xcz5hiwvw4tpae.appsync-api.ap-south-1.amazonaws.com/graphql",
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "da2-qpy4hr4mejbufnkfd3quqkacym",
          },
        }
      );

      setShowOTP(true);

      // setShowPersonalInfo(true);
    } catch (error) {
      messageApi.open({
        type: "warning",
        content: "An account with the given email already exists.",
      });
      console.error("Error signing up :", error);
    }
  };

  const handlePiData = async (e) => {
    e.preventDefault();

    const query = `mutation MyMutation(
      $address: String,
      $city: String, 
      $dob: AWSDate, 
      $gender: String, 
      $profilePhoto: String, 
      $state: String, 
      $email: AWSEmail!,
      $id: ID!
      ) 
      {
        updateUserProfile(input: {
        address: $address, 
        city: $city, 
        dob: $dob, 
        gender: $gender,
        profilePhoto: $profilePhoto, 
        state: $state, 
        email: $email,
        id: $id
        }) {
        id
      }
    }`;

    const variables = {
      address: address,
      city: city,
      dob: dob,
      gender: gender,
      id: userID,
      profilePhoto: selectedFile,
      state: dbState,
      email: email,
    };

    try {
      await axios.post(
        "https://5kz3niiwirg2xcz5hiwvw4tpae.appsync-api.ap-south-1.amazonaws.com/graphql",
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "da2-qpy4hr4mejbufnkfd3quqkacym",
          },
        }
      );

      setShowBmiInfo(true);
      setShowPersonalInfo(false);
    } catch (error) {
      messageApi.open({
        type: "warning",
        content: "An account with the given email already exists.",
      });
      console.error("Error signing up:", error);
    }
  };

  const validateMobileNumber = (value) => {
    const isValid = /^\d{10}$/.test(value);
    if (!isValid) {
      setMobileNumberError("Please enter a valid 10-digit mobile number.");
    } else {
      setMobileNumberError("");
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);

    if (!isValid) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesData = csc.getAllCountries();
        const updatedCountries = countriesData.map((country) => ({
          label: country.name,
          value: country.id, // Change this to the appropriate property for country value
          ...country,
        }));
        setCountries(updatedCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    // Set default country and states
    if (countries.length > 0) {
      // Find the country with the name "India"
      const indiaCountry = countries.find(
        (country) => country.name === "India"
      );
      if (indiaCountry) {
        const defaultStates = updatedStates(indiaCountry.value);

        setStates(defaultStates);
      }
    }
  }, [countries]);

  useEffect(() => {
    // Set default state and cities
    if (selectedState && selectedState !== "") {
      const defaultCities = updatedCities(selectedState);
      setCities(defaultCities);
    }
  }, [selectedState]);

  const updatedStates = (countryId) =>
    csc.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.id, // Change this to the appropriate property for state value
      ...state,
    }));

  const updatedCities = (stateId) =>
    csc.getCitiesOfState(stateId).map((city) => ({
      label: city.name,
      value: city.id, // Change this to the appropriate property for city value
      ...city,
    }));

  const handleStateChange = (e) => {
    const selectedStateId = e.target.value;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setDbState(selectedLabel);
    setSelectedState(selectedStateId);

    const stateCities = updatedCities(selectedStateId);
    setCities(stateCities);
  };

  const fetchInsCompany = async () => {
    const query = `query MyQuery($limit: Int = 100) {
      listInsuranceCompanies(limit: $limit) {
        items {
          id
          name
        }
      }
    }
      `;
    const response = await axios.post(
      "https://5kz3niiwirg2xcz5hiwvw4tpae.appsync-api.ap-south-1.amazonaws.com/graphql",
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "da2-qpy4hr4mejbufnkfd3quqkacym",
        },
      }
    );
    setInsuranceList(response?.data?.data?.listInsuranceCompanies?.items);
  };

  useEffect(() => {
    fetchInsCompany();
  }, []);

  const [hasInsurance, setHasInsurance] = useState(false);
  const handleInsurance = (e) => {
    setHasInsurance(e.target.value);
  };

  const [hasMedical, setHasMedical] = useState(false);
  const handleMedical = (e) => {
    setHasMedical(e.target.value);
  };

  const [medCompany, setMedCompany] = useState("");
  const handleill = (e) => {
    setMedCompany(e.target.value);
  };

  const [illness, setillness] = useState("");
  const handleinsCo = (e) => {
    setillness(e.target.value);
  };

  const [weight, setWeight] = useState("");
  const handleWeight = (e) => {
    setWeight(e.target.value);
  };

  const [height, setheight] = useState("");
  const handleHeight = (e) => {
    setheight(e.target.value);
  };

  const handleBMI = async (e) => {
    try {
      e.preventDefault();

      const query = `mutation MyMutation(
        $email: AWSEmail!,
        $height: String,
        $insuracneCompanyName: String,
        $medicalConditions: String,
        $id: ID!
        $weight: String)
        {
          updateUserProfile(input: {
          email: $email,
          height: $height,
          insuracneCompanyName: $insuracneCompanyName,
          medicalConditions: $medicalConditions,
        id: $id,
          weight: $weight}) {
          id
        }
      }`;

      const variables = {
        medicalConditions: medCompany,
        insuracneCompanyName: illness,
        weight: weight,
        height: height,
        email: email,
        id: userID,
      };

      await axios.post(
        "https://5kz3niiwirg2xcz5hiwvw4tpae.appsync-api.ap-south-1.amazonaws.com/graphql",
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "da2-qpy4hr4mejbufnkfd3quqkacym",
          },
        }
      );

      setPaymentInfo(true);
      setShowBmiInfo(false);
      setShowPersonalInfo(false);
    } catch (e) {
      console.log(e);
    }
  };

  const data = {
    name: "Nobesity India",
    amount: 799,
    number: "9574944952",
    MUID: "MUID" + Date.now(),
    transactionId: "T" + Date.now(),
  };

  const [loading2, setLoading2] = useState(false);

  const handleCheckout = (e) => {
    e.preventDefault();
    try {
      axios
        .post(
          "https://nefme8guw4.execute-api.ap-south-1.amazonaws.com/prod/payment",
          { ...data }
        )
        .then((res) => {
          const urlData = res?.data?.url;
          if (urlData !== null || urlData !== undefined) {
            window.location.href = urlData;
          }
        });

      // setShowThankYou(true)
    } catch (err) {
      console.log(err);
    }
  };

  const [otp, setOtp] = useState(null);

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    const cognito = new CognitoIdentityServiceProvider({
      region: "ap-south-1",
    });

    const params = {
      ClientId: "1ggg857po8jtsno9ijqanls4a7",
      ConfirmationCode: otp, // Assuming 'otp' is the variable where you store the user-entered OTP
      Username: email,
    };

    try {
      await cognito.confirmSignUp(params).promise();

      setShowOTP(false);
      setShowPersonalInfo(true);
    } catch (error) {
      messageApi.open({
        type: "warning",
        content: "Invalid OTP. Please try again.",
      });
      console.error("Error confirming sign up:", error);
    }
  };

  return (
    <>
      {contextHolder}
      <section
        id="hero"
        className="d-flex align-items-center login-page login-contact"
      >
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-items-stretch">
              <div className="php-email-form login-ff">
                <a href="/">
                  <img src={logo} className="img-fluid" alt="" />
                </a>
                {showthankYou ? (
                  <div className="row">
                    <div className="about-highlights login-wraper">
                      <h4
                        style={{
                          paddingTop: "50px",
                          fontSize: "50px",
                          lineHeight: "50px",
                          fontWeight: 600,
                        }}
                      >
                        🤝 <br /> Thank you for joining with us{" "}
                      </h4>

                      <p style={{ paddingBottom: "50px" }}>
                        We will update you soon regarding the Nobesity Campaign
                        in your registered Email ID.
                      </p>

                      <p>
                        Download this Nobesity app to continue with your Fit
                        India journey
                      </p>

                      <div className="about-highlights d-flex">
                        <a href="https://drive.google.com/file/d/1km_HJkS3FFX90LBMDHKOBOqe0Xd7SJSV/view?usp=drive_link"><img src={google} alt="" /></a>
                        <a href= "https://apps.apple.com/in/app/nobesity-india/id6476114262"><img src={ios} alt="" style={{ paddingLeft: "10px" }} /></a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {showOTP ? (
                      <div className="row" style={{ height: "70vh" }}>
                        <form
                          id="registrationForm"
                          role="form"
                          className=""
                          onSubmit={handleConfirmSignUp}
                        >
                          <div className="row">
                            <div className="about-highlights login-wraper">
                              <h4>Enter OTP</h4>
                            </div>

                            <div className="form-group col-md-12">
                              <div className="field">
                                <input
                                  type="text"
                                  required
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                                <label>Otp*</label>
                              </div>
                            </div>
                          </div>

                          <div className="text-center d-flex mt-3">
                            <button type="submit">Submit</button>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <>
                        <div className="row">
                          {showPersonalInfo || showBmiInfo || paymentInfo ? (
                            <>
                              <div className="about-highlights login-wraper mt-5">
                                <div className="d-flex">
                                  <div className="col-lg-4">
                                    <p className="login-stepper">
                                      Personal <br /> Information
                                    </p>
                                    <div
                                      className="stepper-line"
                                      style={{
                                        background: `${
                                          showPersonalInfo ||
                                          showBmiInfo ||
                                          paymentInfo
                                            ? "#ff8a00"
                                            : ""
                                        }`,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="col-lg-4">
                                    <p className="login-stepper">
                                      Health <br /> and BMI
                                    </p>
                                    <div
                                      className="stepper-line"
                                      style={{
                                        background: `${
                                          showBmiInfo || paymentInfo
                                            ? "#ff8a00"
                                            : "#FFF"
                                        }`,
                                        border: "1px solid #ccc",
                                      }}
                                    ></div>
                                  </div>
                                  <div className="col-lg-4">
                                    <p className="login-stepper">
                                      Contribution <br />
                                      &nbsp;
                                    </p>
                                    <div
                                      className="stepper-line"
                                      style={{
                                        background: `${
                                          paymentInfo ? "#ff8a00" : "#FFF"
                                        }`,
                                        border: "1px solid #ccc",
                                      }}
                                    ></div>
                                  </div>
                                </div>

                                {showBmiInfo ? (
                                  <p className="mt-5">Health Related Data</p>
                                ) : paymentInfo ? (
                                  ""
                                ) : (
                                  <p className="mt-5">
                                    Our ultimate goal is a fit youth, a fit
                                    India, and a prosperous India.
                                  </p>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {showBmiInfo ? (
                            <>
                              <form
                                id="registrationForm3"
                                role="form"
                                onSubmit={handleBMI}
                              >
                                <div className="row">
                                  <div className="form-group col-md-12">
                                    <div className="field">
                                      <select
                                        class="form-select login-select"
                                        placeholder="Select"
                                        onChange={handleInsurance}
                                        required
                                      >
                                        <option value="" disabled selected>
                                          Do you have insurance ?
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    </div>
                                    {hasInsurance === "Yes" ? (
                                      <div className="field">
                                        <select
                                          class="form-select login-select"
                                          placeholder="Select"
                                          onChange={handleinsCo}
                                          required
                                        >
                                          <option value="" disabled selected>
                                            Need text related asking insurance
                                            company
                                          </option>
                                          {insuranceList.map((ins) => (
                                            <option
                                              key={ins.name}
                                              value={ins.name}
                                            >
                                              {ins.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="form-group col-md-12">
                                    <div className="field">
                                      <select
                                        class="form-select login-select"
                                        placeholder="Select"
                                        onChange={handleMedical}
                                        required
                                      >
                                        <option value="" disabled selected>
                                          Do you have any Co-Morbid Condition ?
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    </div>

                                    {hasMedical === "Yes" ? (
                                      <div className="field">
                                        <select
                                          class="form-select login-select"
                                          placeholder="Select"
                                          required
                                          onChange={handleill}
                                        >
                                          <option value="" disabled selected>
                                            Select Option
                                          </option>
                                          <option value="Hypertension (Blood pressure)">
                                            Hypertension (Blood pressure)
                                          </option>
                                          <option value="Diabetes">
                                            Diabetes
                                          </option>
                                          <option value="Hypothyroidism">
                                            Hypothyroidism
                                          </option>
                                          <option value="Hyperthyroidism">
                                            Hyperthyroidism
                                          </option>
                                          <option value="Others">Others</option>
                                        </select>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <p className="mt-5">BMI calculator</p>
                                  <div className="form-group col-md-6">
                                    <div className="field input-group bmi-data">
                                      <input
                                        type="number"
                                        required
                                        maxLength={8}
                                        onChange={handleWeight}
                                      />

                                      <label>Weight</label>
                                      <div class="input-group-append">
                                        <span class="input-group-text">Kg</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <div className="field input-group bmi-data">
                                      <input
                                        type="number"
                                        required
                                        maxLength={8}
                                        onChange={handleHeight}
                                      />
                                      <label>Height</label>
                                      <div class="input-group-append">
                                        <span class="input-group-text">Cm</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="mt-3 col-md-3 d-flex align-items-center justify-content-start">
                                      <p className="d-flex align-items-center">
                                        <svg
                                          width="17"
                                          height="11"
                                          viewBox="0 0 17 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.04053 5.61743H15.0405M1.04053 5.61743L5.04053 9.61743M1.04053 5.61743L5.04053 1.61743"
                                            stroke="#838383"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>{" "}
                                        &nbsp; Back
                                      </p>
                                    </div>
                                    <div className="mt-3 col-md-3 d-flex align-items-center justify-content-center">
                                      <button type="submit">Next</button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </>
                          ) : (
                            ""
                          )}

                          {showPersonalInfo ? (
                            <>
                              <form
                                id="registrationForm22"
                                role="form"
                                onSubmit={handlePiData}
                              >
                                <div className="row">
                                  <div className="form-group col-md-12">
                                    <div className="field">
                                      <input
                                        type="file"
                                        class="file-input__input"
                                        onChange={handleFileChange}
                                        required
                                        accept=".jpg, .jpeg, .png, .heic"
                                      />
                                      <label class="file-input__label">
                                        <svg
                                          width="25"
                                          height="25"
                                          viewBox="0 0 25 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.52197 15.6174C9.84785 15.95 10.2368 16.2143 10.6661 16.3946C11.0954 16.575 11.5563 16.6679 12.022 16.6679C12.4876 16.6679 12.9486 16.575 13.3778 16.3946C13.8071 16.2143 14.1961 15.95 14.522 15.6174M15.022 11.6174H15.032M9.02197 11.6174H9.03197M5.02197 7.61743H6.02197C6.55241 7.61743 7.06111 7.40672 7.43619 7.03165C7.81126 6.65657 8.02197 6.14786 8.02197 5.61743C8.02197 5.35222 8.12733 5.09786 8.31487 4.91033C8.5024 4.72279 8.75676 4.61743 9.02197 4.61743H15.022C15.2872 4.61743 15.5415 4.72279 15.7291 4.91033C15.9166 5.09786 16.022 5.35222 16.022 5.61743C16.022 6.14786 16.2327 6.65657 16.6078 7.03165C16.9828 7.40672 17.4915 7.61743 18.022 7.61743H19.022C19.5524 7.61743 20.0611 7.82815 20.4362 8.20322C20.8113 8.57829 21.022 9.087 21.022 9.61743V18.6174C21.022 19.1479 20.8113 19.6566 20.4362 20.0316C20.0611 20.4067 19.5524 20.6174 19.022 20.6174H5.02197C4.49154 20.6174 3.98283 20.4067 3.60776 20.0316C3.23269 19.6566 3.02197 19.1479 3.02197 18.6174V9.61743C3.02197 9.087 3.23269 8.57829 3.60776 8.20322C3.98283 7.82815 4.49154 7.61743 5.02197 7.61743Z"
                                            stroke="#00A416"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>

                                        <span>
                                          {selectedFile
                                            ? selectedFile.name
                                            : "Upload latest photo"}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <div className="field">
                                      <select
                                        class="form-select login-select"
                                        placeholder="Select"
                                        required
                                        onChange={handleGender}
                                      >
                                        <option value="" disabled selected>
                                          Gender*
                                        </option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <div className="field login-date">
                                      <DatePicker
                                        placeholder="Date Of Birth"
                                        onChange={handleDateChange}
                                        disabledDate={(d) =>
                                          !d || d.isAfter(new Date())
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <div className="field">
                                      <select
                                        className="form-select login-select"
                                        onChange={handleStateChange}
                                        required
                                      >
                                        <option value="" disabled selected>
                                          Select State*
                                        </option>
                                        {states.map((state) => (
                                          <option
                                            key={state.value}
                                            value={state.value}
                                          >
                                            {state.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <div className="field">
                                      <select
                                        className="form-select login-select"
                                        required
                                        onChange={handleCity}
                                      >
                                        <option value="" disabled selected>
                                          Select City*
                                        </option>
                                        {cities?.map((city) => (
                                          <option
                                            key={city.value}
                                            value={city.label}
                                          >
                                            {city.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-12">
                                    <div className="field text-area">
                                      <textarea
                                        className="form-control"
                                        name="message"
                                        rows="2"
                                        required
                                        onChange={handleAddressChange}
                                      ></textarea>
                                      <label>Address</label>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <div className="mt-3 col-md-3 d-flex align-items-center justify-content-center">
                                      <button type="submit">Next</button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </>
                          ) : (
                            ""
                          )}

                          {paymentInfo ? (
                            <>
                              <form
                                id="registrationForm39"
                                role="form"
                                onSubmit={handleCheckout}
                              >
                                <div className="col-md-12">
                                  <div className="about-highlights">
                                    <p>
                                      We appreciate your contribution of{" "}
                                      <span
                                        className="bold-about"
                                        style={{ paddingRight: "0px" }}
                                      >
                                        799 rupees in this campaign regarding
                                        obesity awareness.
                                      </span>
                                    </p>

                                    <p>
                                      Nobesity-India fights with obesity assures
                                      you, this money will be used for execution
                                      of campaign for awareness of OBESITY and
                                      its impact on our youth and hence
                                      impacting prosperity of our country. Here
                                      prize money is only to give motivation to
                                      participants towards first step of our
                                      fight against obesity.
                                    </p>

                                    <div className="form-group col-md-12 pb-3">
                                      <div className="field">
                                        <input type="text" />

                                        <label>Referral Id</label>
                                      </div>
                                    </div>

                                    <p>
                                      <span
                                        className="bold-about"
                                        style={{ paddingRight: "0px" }}
                                      >
                                        Our mission is FIT YOUTH#FIT
                                        INDIA#PROSPEROUS INDIA
                                      </span>
                                    </p>
                                  </div>

                                  <div className="col-md-12 login-gr">
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        className="login-checkbox"
                                        required
                                      />
                                      <div>
                                        <span>
                                          By verifying this information, we
                                          confirm its authenticity.
                                        </span>
                                        <br /> Once payment is made, the
                                        information cannot be edited.
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        className="login-checkbox"
                                        required
                                      />
                                      <div>
                                        <span>
                                          Clicking on the check box represents
                                          that you accept
                                        </span>
                                        <br />
                                        all Terms & Conditions/Privacy
                                        Policies/Refund policy.
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="mt-3 col-md-3 d-flex align-items-center justify-content-start">
                                    <p className="d-flex align-items-center">
                                      <svg
                                        width="17"
                                        height="11"
                                        viewBox="0 0 17 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1.04053 5.61743H15.0405M1.04053 5.61743L5.04053 9.61743M1.04053 5.61743L5.04053 1.61743"
                                          stroke="#838383"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>{" "}
                                      &nbsp; Back
                                    </p>
                                  </div>
                                  <div className="mt-3 col-md-5 d-flex align-items-center justify-content-center">
                                    <button type="submit">
                                      Contribute 799/-
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}

                    {showBmiInfo ||
                    showPersonalInfo ||
                    paymentInfo ||
                    showOTP ? (
                      ""
                    ) : (
                      <>
                        <form
                          id="registrationForm"
                          role="form"
                          className=""
                          onSubmit={handleSignUp}
                        >
                          <div className="row">
                            <div className="about-highlights login-wraper">
                              <h4>Let’s begin</h4>

                              <p>
                                Our ultimate goal is a fit youth, a fit India,
                                and a prosperous India.
                              </p>
                            </div>

                            <div className="form-group col-md-12">
                              <div className="field">
                                <input
                                  type="text"
                                  required
                                  onChange={(e) => setUsername(e.target.value)}
                                />
                                <label>Full name*</label>
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <div className="field">
                                <input
                                  type="text"
                                  maxLength={10}
                                  required
                                  value={mobileNumber}
                                  onChange={(e) => {
                                    setMobileNumber(e.target.value);
                                    validateMobileNumber(e.target.value);
                                  }}
                                />
                                <label>Mobile Number*</label>
                              </div>
                              {mobileNumberError && (
                                <p style={{ color: "red" }}>
                                  {mobileNumberError}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <div className="field">
                                <input
                                  type="text"
                                  required
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateEmail(e.target.value);
                                  }}
                                />
                                <label>Email Address*</label>
                              </div>
                              {emailError && (
                                <p style={{ color: "red" }}>{emailError}</p>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <div className="field input-group bmi-data">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  required
                                  onChange={handlePasswordChange}
                                  maxLength={20}
                                />
                                <label>Password*</label>
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text"
                                    onClick={togglePasswordVisibility}
                                  >
                                    {showPassword ? (
                                      <EyeInvisibleOutlined />
                                    ) : (
                                      <EyeOutlined />
                                    )}
                                  </span>
                                </div>
                              </div>
                              {passwordError && (
                                <p style={{ color: "red" }}>{passwordError}</p>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <div className="field input-group bmi-data">
                                <input
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  required
                                  onChange={handleConfirmPasswordChange}
                                />
                                <label>Confirm Password*</label>
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text"
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    {showConfirmPassword ? (
                                      <EyeInvisibleOutlined />
                                    ) : (
                                      <EyeOutlined />
                                    )}
                                  </span>
                                </div>
                              </div>
                              {confirmPasswordError && (
                                <p style={{ color: "red" }}>
                                  {confirmPasswordError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form-group col-md-12 login-gr">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="login-checkbox"
                                required
                              />
                              <div>
                                <span>
                                  Clicking on the check box represents that you
                                  accept
                                </span>
                                <br />
                                all Terms & Conditions/Privacy Policies/Refund
                                policy.
                              </div>
                            </div>
                          </div>
                          <div className="text-center d-flex mt-3">
                            <button type="submit">Create Account</button>
                          </div>
                        </form>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div
              className="col-lg-1 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            ></div>
            <div
              className="col-lg-5 order-1 order-lg-2 hero-img d-flex align-items-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src={banner} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
