import React, { useEffect } from "react";
import "./App.css";
import Home from "./component/Home";
import AOS from "aos";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./component/Login";
import UploadVideo from "./component/UploadVideo";
import RecordVideo from "./component/RecordVideo";
import MainJs from "./component/MainJs";
import Contact from "./component/Contact";
import About from "./component/About";
import Volunteer from "./component/Volunteer";
import Privacy from "./component/Privacy";
import Term from "./component/Term";
import TakePledge from "./component/Pledge/TakePledge";
import PaymentSuccess from "./component/PaymentSuccess";
import Refund from "./component/Refund";
import Events from "./component/Events";
import DeleteUser from "./component/DeleteUser";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 300,
      duration: 500,
      easing: "ease",
      once: false,
      mirror: false,
    });
    document.addEventListener("aos:out", AOS.refresh);
    return () => {
      document.removeEventListener("aos:out", AOS.refresh);
    };
  }, []);
  return (
    <>
      <MainJs />

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/volunteer" element={<Volunteer />} />
          <Route path="/upload" element={<UploadVideo />} />
          <Route path="/record-video" element={<RecordVideo />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/t&c" element={<Term />} />
          <Route path="/events" element={<Events />} />
          <Route path="/take-pledge" element={<TakePledge />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/refund-policy" element={<Refund />} />
          <Route path="/delete" element={<DeleteUser />} />
       
        </Routes>
      </Router>
    </>
  );
}

export default App;
