import React from "react";
import banner from "../assets/img/herobanner.svg";
import logo from "../assets/img/loginlogo.svg";
import google from "../assets/img/goggle.png";
import ios from "../assets/img/appstore.png";
export default function PaymentSuccess() {
  return (
    <>
      <section
        id="hero"
        className="d-flex align-items-center login-page login-contact"
      >
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-items-stretch">
              <div className="php-email-form login-ff">
                <a href="/">
                  <img src={logo} className="img-fluid" alt="" />
                </a>

                <div className="row">
                  <div className="about-highlights login-wraper">
                    <h4
                      style={{
                        paddingTop: "50px",
                        fontSize: "50px",
                        lineHeight: "50px",
                        fontWeight: 600,
                      }}
                    >
                      🤝 <br /> Thank you for joining with us{" "}
                    </h4>

                    <p style={{ paddingBottom: "50px" }}>
                      We will update you soon regarding the Nobesity Campaign in
                      your registered Email ID.
                    </p>

                    <p>
                      Download this Nobesity app to continue with your Fit India
                      journey
                    </p>

                    <div className="about-highlights d-flex">
                        <a href="https://drive.google.com/file/d/1km_HJkS3FFX90LBMDHKOBOqe0Xd7SJSV/view?usp=drive_link"><img src={google} alt="" /></a>
                        <a href= "https://apps.apple.com/in/app/nobesity-india/id6476114262"><img src={ios} alt="" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-1 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            ></div>
            <div
              className="col-lg-5 order-1 order-lg-2 hero-img d-flex align-items-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src={banner} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
