import React, { useEffect, useState } from "react";
import banner1 from "../assets/img/herobanner.svg";
import getStarted from "../assets/img/icons/getstart.svg";
import takePledge from "../assets/img/icons/pledge.svg";
import play from "../assets/img/icons/play.svg";
export default function Hero() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const contentList = [
    {
      title: "Welcome to Nobesity",
      detail: "A Campaign for a Healthier India💪",
      para: "Our ultimate goal is a fit youth, a fit India, and a prosperous India. Join the Nobesity movement with full vigor and contribute to making India healthier and stronger.",
      btn: "Get Started",
      image: getStarted,
    },
    {
      title: "Nobesity",
      detail: "India Fights With Obesity💪",
      para: "Come Lets Pledge For It. This Campaign Is To Fight With New Pandemic Which Is Sweeping India Quietly. So Come Lets Join This Movement And Unitedly Form A Health Team India And Fight Against it.",
      btn: "Take Pledge",
      image: takePledge,
    },
  ];

  // const rotateContent = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % contentList.length);
  // };

  const rotateContent = () => {
    if (!isHovered) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentList.length);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(rotateContent, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [isHovered]);

  // useEffect(() => {
  //   const intervalId = setInterval(rotateContent, 5000);

  //   // Cleanup the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-5 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="200"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="ver-line">
              <h1 id="rotate-title">{contentList[currentIndex].title}</h1>
              <h1 className="title-detail" id="rotate-details">
                {contentList[currentIndex].detail}
              </h1>
            </div>

            <p className="title-para" id="rotate-des">
              {contentList[currentIndex].para}
            </p>

            <div className="d-flex justify-content-center">
              {contentList.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`underline-pagination ${
                      currentIndex === index ? "active" : ""
                    }`}
                  ></div>
                  {index !== contentList.length - 1 && <div>&nbsp;&nbsp;</div>}
                </React.Fragment>
              ))}
            </div>

            <div className="d-flex justify-content-center justify-content-lg-start align-items-center">
              <div id="btn-str">
                <a
                  id="rotate-button"
                  href={
                    contentList[currentIndex].btn === "Take Pledge"
                      ? "/take-pledge"
                      : "/login"
                  }
                  className="btn-get-started scrollto"
                >
                  <span id="rotate-btn-text">
                    {contentList[currentIndex].btn}
                  </span>
                  <img
                    src={contentList[currentIndex].image}
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/watch?v=A3z_vrxEg_E&t=8s"
                  className="glightbox btn-watch-video"
                >
                  <img src={play} alt="" className="img-fluid" />
                  <span className="pb-1">Play Video</span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-1 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="200"
          ></div>
          <div
            className="col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <img src={banner1} className="img-fluid animated" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
